@import "styles/variables.scss";

.raw-grid-container {
    .arrow-navigation {
        display: none;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;

        @media screen and (max-width: 750px) {
            display: flex;
            padding-bottom: 1rem;
        }
    }
}

.raw-grid {
    display: grid;
    gap: .25rem;
    align-items: center;
    transition: .05s padding ease-in-out;

    .raw-grid-row {
        display: grid;
        gap: .25rem;
        align-items: center;

        .item {
            padding: .75rem;
            background: #F8F8F8;
            text-align: center;
            box-sizing: border-box;
            min-height: 6rem;
            height: 100%;
            display: flex;
            // &:nth-child(2n) {
            //     background: #F8F8F8;
            // }
            border: 1px solid transparent;
            border-radius: 3px;

            &.value-item {
                display: flex;
                flex-wrap: wrap;
                gap: .25rem;
                cursor: pointer;
                opacity: 1;
                transition: .05s all ease-in-out;

                &:hover {
                    opacity: .9;
                }
            }

            &.top-label,
            &.left-label {
                text-align: left;
                background: #F8F8F8;
                flex-wrap: wrap;
                user-select: none;
            }

            &.top-label {
                min-height: 4rem;
            }

            &.left-label {}
        }

        .ico {
            display: flex;
            margin: auto;
            font-size: 4rem;
            color: #E0E0E0;

            @media screen and (max-width: $mobile-breakpoint) {
                max-width: 4rem;
            }

            &.eaten {
                fill: #E0E0E0;
                font-size: 3.3rem;
            }

            &.tick {
                fill: #598B35;
                font-size: 2.65rem;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        overflow: auto;
        -webkit-overflow-scrolling: auto
    }

    @media screen and (max-width: 750px) {
        &.with-navigation {
            .raw-grid-row {
                &[data-mb-length="7"] {
                    grid-template-columns: 7rem repeat(7, 1fr) !important;
                }

                &[data-mb-length="6"] {
                    grid-template-columns: 7rem repeat(6, 1fr) !important;
                }

                &[data-mb-length="5"] {
                    grid-template-columns: 7rem repeat(5, 1fr) !important;
                }

                &[data-mb-length="4"] {
                    grid-template-columns: 7rem repeat(4, 1fr) !important;
                }

                &[data-mb-length="3"] {
                    grid-template-columns: 7rem repeat(3, 1fr) !important;
                }

                &[data-mb-length="2"] {
                    grid-template-columns: 7rem repeat(2, 1fr) !important;
                }

                &[data-mb-length="1"] {
                    grid-template-columns: 7rem repeat(1, 1fr) !important;
                }

                .item {
                    &[data-mb="0"] {
                        display: none !important;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        gap: .4rem !important;

        .raw-grid-row {
            gap: .4rem !important;

            .item {
                &.left-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 15rem !important;
                    padding: .3rem 0 !important;
                    font-size: 1.1rem !important;

                    span {
                        transform: rotate(-90deg);
                        transform-origin: center center;
                        min-width: 10rem;
                    }
                }

                &.value-item {}

                .week-grid-tile {
                    border: 0;

                    .meal-override,
                    .meal-pattern {
                        .name {
                            white-space: break-spaces;
                            word-break: break-word;
                        }
                    }
                }
            }
        }

        &.with-navigation {
            .raw-grid-row {
                &[data-mb-length="7"] {
                    grid-template-columns: 2rem repeat(7, 1fr) !important;
                }

                &[data-mb-length="6"] {
                    grid-template-columns: 2rem repeat(6, 1fr) !important;
                }

                &[data-mb-length="5"] {
                    grid-template-columns: 2rem repeat(5, 1fr) !important;
                }

                &[data-mb-length="4"] {
                    grid-template-columns: 2rem repeat(4, 1fr) !important;
                }

                &[data-mb-length="3"] {
                    grid-template-columns: 2rem repeat(3, 1fr) !important;
                }

                &[data-mb-length="2"] {
                    grid-template-columns: 2rem repeat(2, 1fr) !important;
                }

                &[data-mb-length="1"] {
                    grid-template-columns: 2rem repeat(1, 1fr) !important;
                }

                .item {
                    &[data-mb="0"] {
                        display: none !important;
                    }
                }
            }
        }
    }
}