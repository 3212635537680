@import "styles/variables";

::selection {
    background: rgba($brand-red, 0.2);
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $background;
    font-size: 100%;
    font-family: $font;
    color: $text;
    line-height: 1.5rem;
}

.font-tiny {
    font-size: $font-small;
}

.font-small {
    font-size: $font-small;
}

.font-normal {
    font-size: $font-normal;
}

.font-large {
    font-size: $font-large;
}

.font-larger {
    font-size: $font-larger;
}

.font-header {
    font-size: $font-header;
}

.text-capitalize {
    text-transform: capitalize;
}

sub {
    font-size: 80%;
    opacity: 0.8;
}

p,
li {
    font-size: $font-normal;
}

h1 {
    font-size: $font-header;
    margin-bottom: 1rem;
}

h2 {
    font-size: $font-large;
    margin-bottom: 0.5rem;
}

h3 {
    font-size: $font-large;
    margin-bottom: 0.5rem;
}

a {
    color: $link-color;
    text-decoration: none;
}

b,
strong {
    font-weight: bold;
}

small {
    font-size: 75% !important;
}

.clear {
    clear: both;
}

.uppercase {
    text-transform: uppercase;
}

.block {
    display: block;
}

.relative {
    position: relative;
}

.word-break-all {
    word-break: break-all;
}

.clickable {
    cursor: pointer;
}

.nopadding {
    padding: 0;
}

.user-select-none {
    user-select: none;
}

.p-event-n {
    pointer-events: none !important;
}


.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

ul {
    margin: 0;
    padding: 0 0 0 2rem;
    list-style-type: none;
}

li {
    margin: 1rem 0 0 0;
    padding: 0;
    list-style-type: disc;
    font-size: $font-large;
}

.nowrap {
    white-space: nowrap;
}

.DayPicker {
    line-height: 1rem;
}

.sticky {
    position: sticky;
    top: 0;
}

.color-green {
    color: $brand-green;
}

.color-red {
    color: $brand-red;
}

.color-grey-transparent {
    color: rgba(128, 128, 128, 0.5);
}

.object-cointain {
    object-fit: contain;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.pre {
    white-space: pre;
}

pre {
    display: block;
    font-family: monospace;
    white-space: pre;
}

// ===================== PADDING ==================== //
// PADDING TOP
.pt-1 {
    padding-top: 0.5rem !important;
}

.pt-2 {
    padding-top: 1rem !important;
}

.pt-3 {
    padding-top: 2rem !important;
}

// PADDING RIGHT
.pr-1 {
    padding-right: 0.5rem !important;
}

.pr-2 {
    padding-right: 1rem !important;
}

.pr-3 {
    padding-right: 2rem !important;
}

// PADDING BOTTOM
.pb-1 {
    padding-bottom: 0.5rem !important;
}

.pb-2 {
    padding-bottom: 1rem !important;
}

.pb-3 {
    padding-bottom: 2rem !important;
}

// PADDING LEFT
.pl-1 {
    padding-left: 0.5rem !important;
}

.pl-2 {
    padding-left: 1rem !important;
}

.pl-3 {
    padding-left: 2rem !important;
}

// TOP BOTTOM
.py-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

// LEFT RIGHT
.px-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.px-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.px-3 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

// ===================== MARGIN ==================== //

.m-auto {
    margin: auto !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

// margin TOP
.mt-1 {
    margin-top: 0.5rem !important;
}

.mt-2 {
    margin-top: 1rem !important;
}

.mt-3 {
    margin-top: 2rem !important;
}

// margin RIGHT
.mr-1 {
    margin-right: 0.5rem !important;
}

.mr-2 {
    margin-right: 1rem !important;
}

.mr-3 {
    margin-right: 2rem !important;
}

// margin BOTTOM
.mb-1 {
    margin-bottom: 0.5rem !important;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.mb-3 {
    margin-bottom: 2rem !important;
}

// margin LEFT
.ml-1 {
    margin-left: 0.5rem !important;
}

.ml-2 {
    margin-left: 1rem !important;
}

.ml-3 {
    margin-left: 2rem !important;
}

// TOP BOTTOM
.my-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

// LEFT RIGHT
.mx-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.mx-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.mx-3 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

// ===================== DISPLAYS ==================== //

.d-grid {
    display: grid;
}

.d-flex {
    display: flex;
}

.d-inline-block {
    display: inline-block;
}

.full {
    width: 100%;
}

.only-print {
    display: none !important;

    @media print {
        display: inline-block;
    }
}

.no-print {
    @media print {
        visibility: hidden;
    }
}

.no-print-d-none {
    @media print {
        display: none;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .mx-mobile-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .text-md-center {
        text-align: center;
    }

    .hide-md {
        display: none;
    }
}

@media screen and (max-width: $small-mobile-breakpoint) {
    .hide-sm {
        display: none;
    }
}

@media screen and (max-width: 470px) {
    .hide-xs {
        display: none;
    }
}

i {
    font-style: italic;
}

.bg-color-1 {
    background-color: $brank-blue3;
}

.sizing-border-box {
    box-sizing: border-box;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-copy {
    cursor: copy;
}

.cursor-grab {
    cursor: grab;
}

.full-width {
    display: inline-block;
    width: 100%;
}

@keyframes checkboxTooltipRoll {
    0% {
        opacity: 0;
        width: 1px;
    }

    25% {
        opacity: 1;
        width: auto;
    }

    99% {
        opacity: 0.65;
        width: auto;
    }

    100% {
        opacity: 0;
        width: 1px;
    }
}

.fraction-display {
    display: inline-block;
    font-weight: bold;
    width: 5rem;

    &.collapse {
        width: auto;
    }

    .integer-part {
        display: inline-block;
        width: 2.5rem;
        font-size: $font-large;
        letter-spacing: 0.8px;
        text-align: right;
    }

    .fraction-part {
        display: inline-block;
        width: 2.5rem;
        font-size: $font-large;
        letter-spacing: 0.8px;
    }
}

.saved-animation::before {
    content: "Saved";
    position: absolute;
    right: 0;
    background: $brand-green;
    color: $white;
    border: 1px solid $white;
    width: 1px;
    opacity: 0;
    padding: 0.25rem;
    animation-name: checkboxTooltipRoll;
    animation-duration: 1.25s;
}

.fadeout>*,
.single-fadeout {
    opacity: 0.1;
    pointer-events: none;
}

.fadeout>.resist-fadeout {
    opacity: 1 !important;
}

.no-decoration {
    text-decoration: none !important;
}

.posr {
    position: relative;
}

.validation {
    text-align: right;
    font-size: $font-normal;
    color: $text-accent;

    &.error {
        color: $validation-error;
    }
}

$sb: $small-breakpoint+1px;
$mb: $mobile-breakpoint+1px;
$emb: $extra-mobile-breakpoint+1px;

.small-only {
    @media all and (min-width: $sb) {
        display: none !important;
    }
}

.mobile-only {
    @media all and (min-width: $mb) {
        display: none !important;
    }
}

.extra-mobile-only {
    @media all and (min-width: $emb) {
        display: none !important;
    }
}

.small-hidden {
    @media all and (min-width: $mobile-breakpoint) and (max-width: $small-breakpoint) {
        display: none !important;
    }
}

.mobile-hidden {
    @media all and (max-width: $mobile-breakpoint) {
        display: none !important;
    }
}

.extra-mobile-hidden {
    @media all and (max-width: $extra-mobile-breakpoint) {
        display: none !important;
    }
}

[data-tutorial="active"] {
    position: relative;
    z-index: 9 !important;
    background-color: #fff;
}

i.italic {
    font-style: italic;
}

@media print {
    iframe#launcher {
        display: none;
    }
}

@supports (-webkit-touch-callout: none) {

    select,
    textarea,
    input {
        font-size: 16px !important;
    }
}

@keyframes wave {

    0%,
    60%,
    100% {
        transform: initial;
    }

    30% {
        transform: translateY(-0.5rem);
    }
}

.ingredient-brand-name {
    font-weight: bold;
    color: #75c469;
    font-style: italic;
    padding-left: 0.2rem;
}

.blue {
    font-weight: bold;
    color: #7599E4;
    font-style: italic;
}


.ingredient-site-name {
    font-style: italic;
    padding-left: 0.2rem;
    text-decoration: underline;
}