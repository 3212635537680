@import "styles/variables.scss";

.login {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url('~images/loginPage_desktop.jpg') 80% center;
    background-size: cover;
    box-sizing: border-box;
    min-height: 55rem;
    height: 100vh;

    .change-password-grid {
        display: grid;
        box-sizing: border-box;
        gap: 1rem;

        .checkbox {
            .checkbox-indicator {
                fill: #9D9FA6;
                position: absolute;

                i.icon {
                    font-size: $font-large !important;
                }
            }

            .checkbox-text {
                padding-left: 3rem;
                display: inline-block;
                font-size: $font-normal;

                a {
                    display: inline-block;
                    color: $text-accent;
                    font-weight: bold;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .button-row {
        display: grid;
        justify-content: flex-end;
        box-sizing: border-box;

        button {
            max-width: 24rem;
        }
    }

    .forgot-form {
        .input-container.left-icon {
            svg {
                fill: #9D9FA6;
            }
        }
    }
}

.login-panel {
    display: block;
    margin: auto;
    padding-top: 4rem;
    box-sizing: border-box;

    .container {
        padding: 1rem 1.25rem;
    }
}

.login-panel>.container {
    position: relative;
    max-width: 30rem;
    width: 100%;
    box-sizing: border-box;
}

.login-header {
    color: $brand-green-border;
    font-size: 4rem;
    text-align: center;
    line-height: 4rem;
    padding-bottom: 1.5rem;
}

.login-header img {
    max-width: 100%;
}

.login-body {
    padding: 1.5rem 0 1rem;

    @media screen and (max-width: $extra-mobile-breakpoint) {
        .space.space-default {
            height: 1.6rem;
        }
    }
}

.login-forgot {
    text-align: right;

    a {
        padding: 0;
        font-size: $font-normal;

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        a {
            padding-left: .3rem;
            padding-right: .3rem;
            line-height: 2.8rem;
        }
    }
}

.login-footer {
    text-align: right;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: minmax(7rem, max-content) minmax(7rem, max-content);
    justify-content: space-between;
    align-items: center;

    .register-button {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .login-button {
        display: flex;
        gap: .5rem;
        align-items: center;
        padding: .5rem 1.75rem;
    }

    &.login-success {
        position: absolute;
        grid-template-columns: 13.25rem;
        z-index: 1;
    }

    .register {
        padding: .5rem 1.5rem !important;
    }

    @media screen and (max-width: $mobile-breakpoint) {

        button,
        a {
            line-height: 2.25rem;
        }
    }
}

.login-subtext {
    color: #666;
    font-size: $font-small;
    line-height: 1rem;
}

@keyframes leafWind {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(2deg);
    }
}

.login-bg-leaves {
    position: absolute;
    left: -6.5rem;
    top: 1rem;
    color: $brand-green;
    font-size: 15rem;
    z-index: -10;
}

.login-bg-leaves svg {
    transform-origin: 100% 0%;
    animation: leafWind 1s ease-in-out infinite alternate;
    fill: $brand-green;
}

@media screen and (max-width: $small-breakpoint) {
    .login {
        background: url('~images/loginPage_desktop.jpg') 100% 100%;
        background-size: cover;
        background: none;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: url('~images/loginPage_desktop.jpg') 100% 100%;
            background-size: cover;
            z-index: 0;
        }

        &>div {
            z-index: 3;
            position: relative;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: rgba(255, 255, 255, 0.8);
            z-index: 1;
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {

    .login-panel {
        padding-top: 2rem;
    }

    .login-panel>.container {
        max-width: none;
        box-sizing: border-box;
    }
}

.login input {
    box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.5);
    background: $header;

    @media screen and (max-width: $mobile-breakpoint) {
        line-height: 2.35rem;
    }
}

.login-message .resend-activation-link {
    &:hover {
        text-decoration: none !important;
    }
}