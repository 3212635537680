@import "styles/variables.scss";

.select-wrapper {
    position: relative;
    display: block;
    padding: 0;

    &.label-relative {
        margin-top: 1.25rem !important;
    }

    select {
        font-size: $font-normal;
        padding: 0.5rem 2rem 0.5rem 0.6rem;
        border-radius: 3px;
        border: 1px solid $border;
        display: block;
        margin: auto;
        box-sizing: border-box;
        width: 100%;
        appearance: none;
        line-height: 2rem;
        background-color: $white;
        color: $text-lighter;

        &.has-value {
            color: $text !important;
        }

        &.has-error {
            border-color: $validation-error !important;
        }

        &.apperance {
            appearance: radio !important;
        }

        option {
            color: $text;
            line-height: 1rem;
            padding: 0.5rem;
        }

        &:focus {
            outline: 1px $brand-main dashed;
        }

        &::placeholder {
            color: $text-lighter;
        }
    }

    &.disabled {
        opacity: 0.7;
        user-select: none;
        pointer-events: none;
    }

    &.inline {
        display: inline-block;
    }

    &.small {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    &.has-error {
        svg {
            fill: rgba(220, 33, 41, 1) !important;
        }

        select {
            border: 1px solid rgba(220, 33, 41, 1);
            position: relative;
        }

        .validation-error {
            color: rgba(220, 33, 41, 1);
            text-align: right;
            padding-top: 0.3rem;
        }
    }
}

.select-wrapper:after {
    /*

@Todo: FA content here

  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;

  text-align: center;
  width: 1.25em;
  line-height: 1.5rem;
  height: 1.5rem;
  
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;

  content: fa-content($fa-var-chevron-down);

  font-size: $font-normal;
  color: $text;

  right: 0;
  top: -1px;

  padding: 0.5rem;

  border-left: 1px solid $border;
  
  position: absolute;
  pointer-events: none;
*/
}