@import "styles/variables.scss";

@keyframes fadeInWithBlock {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.loading {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    
    transition: 0.4s all;

    &.without-transition {
        transition: none !important;
    }

    &.invisible {
        opacity: 0;
        pointer-events: none;
        transition: 0.4s all;
    }

    &.transparent {
        background: transparent;
    }

    &.white {
        background-color: #fff;
    }

    &.spinner-hidden {
        .logo {
            top: 50%;
        }

        .loading-knob {
            display: none;
        }
    }

    .logo {
        top: 30%;
        left: 50%;
        position: absolute;
        display: block;
        transform: translate(-50%, -50%);
        box-sizing: border-box;

        img {
            max-width: 30rem;
            margin: 0 auto;
            display: block;
        }
    }
}

.loading-small {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10000;

    &.loading-tiny {
        .loading-knob {
            width: 20rem;
            height: 6rem;
            margin-top: -3rem;
            margin-left: -10rem;
        }

        .loading-knob-bg {
            font-size: 4rem;
            line-height: 5rem;
        }

        .loading-knob-fg {
            font-size: 3rem;
            line-height: 5rem;
        }

        .loading-knob-text {
            font-size: $font-header;
            line-height: 3rem;
            padding-top: 4rem;
        }
    }
}

.loading-small,
.loading {
    .loading-knob {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        text-align: center;
        width: 30rem;
        height: 14rem;
        margin-top: -7rem;
        margin-left: -15rem;
    }

    .loading-knob-bg {
        font-size: 8rem;
        color: rgba(#ccc, 1);
        text-shadow: #000 1px 0 10px;
        line-height: 10rem;
    }

    .loading-knob-fg {
        font-size: 7rem;
        color: rgba(#fff, 1);
        text-shadow: #000 1px 0 10px;
        line-height: 10rem;
    }

    .loading-knob-text {
        font-size: 4rem;
        color: rgba(255, 255, 255, 1);
        line-height: 4rem;
        text-shadow: #000 1px 0 10px;
        padding-top: 10rem;
    }

    .fa-spin-ease {
        animation-direction: normal !important;
        animation-timing-function: ease-in-out !important;
    }

    .fa-spin-ease-other {
        animation-direction: reverse !important;
        animation-timing-function: linear !important;
    }
}

.loading-dots {
    width: 100%;
    display: inline-block;
    z-index: 100;
    box-sizing: border-box;

    &>div {
        display: inline-block;
        border-radius: 50%;
        animation: wave 1.3s linear infinite;
        box-sizing: border-box;

        &:nth-child(2) {
            animation-delay: -1.1s;
        }

        &:nth-child(3) {
            animation-delay: -0.9s;
        }
    }

    &.hidden {

        &,
        &>div {
            display: none;
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .loading {
        .logo {
            padding: 0 1rem;
            width: 100%;

            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

@media screen and (max-width: $extra-mobile-breakpoint) {
    .loading {
        .logo {
            img {
                max-width: 24rem;
            }
        }
    }
}