@import "styles/variables.scss";

.notification-wrapper {
    display: grid;
    gap: .5rem;
    position: fixed;
    z-index: 2;
    top: 1rem;
    right: 1rem;
    z-index: 999;
    box-sizing: border-box;
    width: 100%;
    max-width: 20rem;
}