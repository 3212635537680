@import "styles/variables.scss";

.header-bar-button.left {
  float: left;
}

.header-bar-button.right { 
  float: right;
}

.header-bar-button {
  padding: 0.75rem;
  font-size: $font-header;
  line-height: 2rem;
  fill: $brand-green-text;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  width: 2.5rem;
  height: 2rem;
  color: #FFF;
}

.header-bar-button.disabled {
  cursor: default;
  pointer-events: none;
}

.header-bar-button i.fa {
  font-size: $font-header; 
}

.header-bar-button.dark {
  background-color: $header-bar-tone1;
}

.header-bar-button.darker {
  background-color: $header-bar-tone2;
}

.header-bar-button i.icon {
  height: 2rem;
  display: block;
}

.header-bar-button i.icon svg {
  height: 2rem;
}

.header-bar-button.active {
  cursor: default;
} 

.header-bar-button.active {
  fill: $brand-green;
  color: $brand-green;
  box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 0.5rem 0.5rem;
}

.header-bar-button:hover {
  fill: lighten($brand-green, 20%);
}

.header-bar-button.active:hover {
  fill: lighten($brand-green, 20%);
}

@media print {
    .header-bar-button {
        display: none;
    }
}