@import "styles/variables.scss";

.modal-wrapper {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    padding: 1rem;
    width: 100%;
    max-height: 100%;

    box-sizing: border-box;

    &>.container {
        max-width: 100%;
        box-sizing: border-box;
        z-index: 1;

        border-radius: 4px;
        border: 1px solid #888;

        &.noborder {
            border: 0;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding-bottom: 5rem;
    }

    &.no-padding {
        padding: 0;
    }

    &.no-overlow {
        overflow: hidden;
    }
}

.modal-wrapper-bg {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    pointer-events: none;
    z-index: 0;
}

.modal-body {
    overflow: hidden;
}

.modal-close-button {
    position: absolute;
    right: .5rem;
    top: .5rem;
    font-size: $font-header;
    z-index: 1;
}

.modal-close-button:hover {
    color: $text-lighter;
}

.modal-extra-small {
    width: 35rem;
}

.modal-small {
    width: 35rem;
}

.modal-medium {
    width: 50rem;
}

.modal-extra-medium {
    width: 70rem;
}

.modal-large {
    width: 75rem;
}

.modal-top {
    margin-top: 0 !important;
}

.modal-top-12 {
    margin-top: 12rem !important;
}

.modal-extra-large {
    width: 90rem;
}

.modal-full {
    width: 100%;
    height: 100%;
}

.modal-light {
    .modal-close-button {
        fill: $white;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .modal-mobile-transparent {
        border: 0 !important;
        background: transparent !important;
    }

    .modal-mobile-center {
        display: inline-block;
        width: auto !important;
        height: auto;
        padding: 0 !important;

        .modal-close-button {
            display: none !important;
        }
    }

    .modal-wrapper {
        padding: .5rem;

        &>.container {
            margin: 0;
            width: 100%;
        }
    }

    .modal-close-button {
        font-size: 2.7rem;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .modal-wrapper {
        padding-bottom: 5rem;
    }
}