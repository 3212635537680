@import "styles/variables.scss";

.nutrients-by-ingredient-modal {
    box-sizing: border-box;

    .modal-title {
        line-height: 2rem;
        padding: 3rem 0 0;
        width: 100%;
        box-sizing: border-box;

        display: grid;
        grid-template-columns: 1fr minmax(min-content, 4rem);
        align-items: center;
    }

    .details {
        font-size: $font-normal;

        .ingredient-element {
            display: grid;
            grid-template-columns: 1fr minmax(min-content, 4rem);
            align-items: center;
            text-transform: capitalize;
            line-height: 2rem;
            padding: 0;

            &.bold {
                .name {
                    font-weight: bold;
                }
            }

        }

        table {
            user-select: none;
            width: 100%;

            tr td {
                text-align: right;
            }

            tr {
                height: 2rem;
            }

            td {
                padding: 0.25rem;
            }

            thead tr {
                background: lighten($brand-green, 15%);

                th {
                    padding: .25rem;
                    box-sizing: border-box;
                }
            }
        }
    }

    @media all and (max-width: $small-breakpoint) {
        padding: 0;

        .details {
            overflow: auto
        }
    }
}