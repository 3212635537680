@import "styles/variables.scss";

.number-field {
    display: grid;
    grid-template-columns: auto minmax(5rem, max-content) auto;
    justify-items: center;
    align-items: center;
    gap: 0.2rem;
    margin-left: 0.2rem;

    &.has-error {
        position: relative;

        .fraction {
            border: 1px solid $validation-error !important;
        }

        .validation-error {
            color: $validation-error;
            padding-top: .3rem;
            text-align: center;
            font-size: $font-small;
            grid-column: span 3;
            width: 100%;
            font-weight: bold;
            line-height: 1rem;
        }
    }

    span {
        cursor: pointer;
    }

    .fraction {
        border: 1px solid transparent;
        margin: 0 0.8rem;
        border-radius: 5px;
        font-size: 1.6rem;
        display: inline-block;
        min-width: 2.7rem;
        text-align: center;
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        cursor: default;
        user-select: none;

        &.no-fraction {
            letter-spacing: normal;
            font-size: $font-large;
        }
    }

    &.disabled {

        span,
        i,
        .fraction {
            cursor: default;
            opacity: 0.96;
        }
    }

    i {
        color: #888;
    }

    &.bordered {
        .fraction {
            border: 1px solid $border-light-grey;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        span {
            width: 100%;
            text-align: center;

            i {
                display: flex;
                margin: auto;
                font-size: $font-large;
                justify-content: center;

            }
        }
    }
}