@import "styles/variables.scss";

.week-calendar {
    grid-area: calendar;
    display: grid;
    gap: 1px;

    grid-template-columns: repeat(7, 1fr);
    padding: 0 1rem;

    polygon {
        fill: lighten($brand-red, 12%);
        stroke: $text-lighter;
        stroke-width: 1;
    }

    svg {
        height: 3rem;
    }

    &.with-arrows {
        grid-template-columns: 2.75rem repeat(7, 1fr) 2.75rem;

        @media screen and (max-width: $extra-mobile-breakpoint) {
            grid-template-columns: 2rem repeat(7, 1fr) 2rem;
        }

    }

    .week-calendar-arrow {
        padding-right: .2rem;
        cursor: pointer;
        align-items: center;
        display: flex;

        i.icon {
            width: 100%;
            line-height: 1;


            svg {
                width: 100%;
                display: flex;

                polygon {
                    fill: #00AAA7;
                }
            }
        }

        &:hover {
            i.icon {
                svg {
                    polygon {
                        fill: darken(#00AAA7, 10%)
                    }
                }
            }
        }

        &.right {
            padding-left: .3rem;
            padding-right: 0;

            i.icon {
                svg {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &.light-blue {
        .box {
            color: $brand-blue-text;

            &.day {
                border: 0;
                border-left: 1px solid;

                background: $rte-blue;

                &.active,
                &:hover {
                    background: $brand-blue;
                }
            }
        }
    }

    .box {
        line-height: 1.4rem;
        padding: .4rem 0 .3rem;
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
        font-size: $font-large;
        color: $brand-red-text;
        border-radius: 3px;

        .day-caption {
            font-size: 1.1rem;
        }

        &.day {
            background: lighten($brand-red, 25%);
            border: 1px solid $text-lighter;

            &.active {
                background: lighten($brand-red, 12%);
            }
        }
    }
}