@import "styles/variables.scss";

.checkbox-indicator {
    font-size: $font-large;
    margin-right: 0.67rem;
    cursor: pointer;

    i.icon {
        font-size: $font-header;
        margin: 0 0.5rem;
    }

    .radio {
        border: 2px solid;
        border-radius: 15px;
        padding: 2px 1px 1px 1px;
        line-height: 1rem;

        &,
        span {
            display: inline-block;
        }

        i.icon {
            font-size: 0.9rem;
            margin: 0;
            vertical-align: baseline;
            line-height: 1;
            visibility: hidden;
        }

        &.visible i.icon {
            visibility: visible;
        }
    }

    &.accent {
        color: $text-accent;

        i.icon {
            fill: $text-accent;
        }
    }

    &.disabled {
        color: gray;

        i.icon {
            fill: gray;
        }
    }

    &.green {
        color: $brand-green;

        i.icon {
            fill: $brand-green;
        }
    }

    &.has-error {
        color: $validation-error !important;

        i.icon {
            fill: $validation-error !important;
        }

        .radio {
            border-color: $validation-error !important;
        }
    }
}
