@import "styles/variables.scss";

.tooltip {
    position: relative;
    cursor: pointer;

    .tooltip-content {
        display: none;
        position: absolute;
        top: -1.7rem;
        font-size: $font-small;
        background-color: $brand-green;
        color: $white;
        padding: 0 .5rem;
        text-transform: none;
        border-radius: 5px;
        right: 50%;
        transform: translate(50%, 0);
        border: 1px solid $white;
        z-index: 9;
        white-space: nowrap;
    }

    &:hover {
        .tooltip-content {
            display: inline-block;
        }
    }

    &.small {
        line-height: 1.5;

        .tooltip-content {
            top: -2rem;
        }
    }

    &.black {
        .tooltip-content {
            background-color: #333;
        }
    }

    &.d-inline-block {
        display: inline-block;
    }

    &.tooltip-mobile-hidden {
        @media screen and (max-width: $extra-mobile-breakpoint) {
            .tooltip-content {
                display: none;
            }
        }
    }
}