@import "styles/variables.scss";

.footer-button.left {
  float: left;
}

.footer-button.right { 
  float: right;
}

.footer-button {
  padding: 1rem;
  font-size: $font-normal;
  fill: rgba($brand-green-text, 0.75);
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
} 

.footer-button i.icon {
  height: 2rem;
  display: block;
}

.footer-button i.icon svg {
  height: 3rem;
}

.footer-button.active {
  cursor: default;
} 

.footer-button.active {
  fill: $brand-red;
}

.footer-button:hover {
  fill: lighten($brand-green, 20%);
}

.footer-button.active:hover {
  fill: lighten($brand-red, 20%);
} 
