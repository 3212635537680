@import "styles/variables.scss";

.replace-modal-restriction-warning {
    gap: .75rem;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
    display: flex;
    padding-bottom: 1rem;

    span {
        line-height: 1.6rem;
        font-size: 1.35rem;
    }

    .icon {
        align-self: flex-start;
    }

    i {
        font-size: 1.5rem;
        color: #da2026;

    }
}

.meal-info-element {
    grid-row: 1 / 2;
    font-size: $font-normal;
    text-align: center;
    display: grid;
    height: 100%;
    align-items: flex-start;
    grid-template-rows: 1fr min-content;

    @media all and (max-width: $mobile-breakpoint) {
        grid-row: unset;
        word-break: break-word;
        white-space: normal;
    }

    .name {
        font-weight: bold;
    }
}

.meal-amount-input {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 0.75rem;
    position: relative;
    margin: 0 auto;
    justify-content: center;

    &.with-select {
        max-width: 18rem;
    }

    .number-field {
        .fraction.no-fraction {
            min-height: 2.15rem;
        }
    }

    select.portion-type-select {
        padding: 0 0.25rem 0 0.5rem;
        font-size: $font-small;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        appearance: auto;
        height: 2.2rem;
        color: black;
    }
}

.number-field {
    i {
        color: #888;
    }
}

.replace-modal {
    box-sizing: border-box;

    .replace-header {
        min-height: 3rem;
        background-color: $modal-header-bar;
        padding-left: 1rem;
        padding-right: 0.5rem;
        user-select: none;

        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        box-sizing: border-box;

        span {
            display: inline-block;
            padding-top: 0.2rem;
            line-height: 1;
        }

        position: relative;

        button {
            position: absolute;
            right: 0.5rem;

            padding: 0;

            span {
                i.icon {
                    padding-left: 0.5rem;

                    svg {
                        fill: $brand-main-text;
                    }
                }
            }
        }

        span,
        button {
            color: $brand-main-text;
        }
    }

    .replace-inner {
        background: $white;
        padding: 2rem 3rem;
        line-height: 2rem;

        .fill-empty-space {
            padding-top: 2rem;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: .5rem;

            color: #ddd;
            opacity: .8;

            span {
                text-transform: uppercase;
                font-size: $font-small;
            }

            i {
                color: #eee;
                font-size: 4rem;
                line-height: 1;
            }
        }

        .site-name {
            text-decoration: underline;
            font-style: italic;
        }

        .replace-icon {
            display: grid;
            justify-items: center;
            grid-row: 1 / 3;
            grid-column: 2 / 3;
            padding: 0 1rem;

            i.icon {
                font-size: 7rem;
                fill: #c9e9e9;
            }

            @media all and (max-width: $mobile-breakpoint) {
                grid-row: unset;
                padding: 1rem 0;
                grid-column: 1 / 4;

                i.icon {
                    font-size: 5rem;
                }
            }
        }

        .left-column {
            grid-column: 1 / 2;

            @media all and (max-width: $mobile-breakpoint) {
                grid-column: 1 / 4;
            }
        }

        .right-column {
            grid-column: 3 / 4;

            @media all and (max-width: $mobile-breakpoint) {
                grid-column: 1 / 4;
            }

            &.full-height {
                grid-row: 1 / 3;

                @media all and (max-width: $mobile-breakpoint) {
                    grid-row: unset;
                }
            }
        }

        .replace-info {
            display: grid;
            grid-template-columns: 1fr min-content 1fr;
            grid-auto-flow: dense;

            @media all and (max-width: $mobile-breakpoint) {
                grid-template-columns: unset;
            }

            user-select: none;
            min-height: 12rem;
            align-items: flex-start;
            padding-bottom: 2rem;

            &.in-progress {
                .right-column {
                    opacity: 0.9;
                }
            }
        }

        .loading-single-item {
            min-height: 8.3rem;
            display: grid;
            align-items: center;
            text-align: center;
        }

        .single-item {
            .meal-amount-input {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }

        .none-selected-desc {
            width: 15rem;
            height: 8rem;
            text-align: center;
            margin: 0 auto;
        }

        .buttons {
            direction: rtl;
            display: grid;
            grid-template-columns: repeat(4, max-content);
            gap: 1rem;
            margin-left: auto;
            padding-top: 1.5rem;
            padding-bottom: 0.5rem;
            position: relative;

            button.back {
                color: $text-accent !important;
                border: 1px solid;
            }

            button.disabled {
                opacity: 0.4 !important;
                pointer-events: initial !important;
            }

            .nutrients-tooltip {
                line-height: 1.5rem;

                .nutrients-title {
                    line-height: 1.5rem;
                    display: none;
                }

                i {
                    vertical-align: bottom;
                }

                position: absolute;
                left: 0;
                top: 1.5rem;

                @media all and (max-width: $mobile-breakpoint) {
                    position: unset;
                    left: unset;
                    top: unset;

                    .nutrients-title {
                        display: inline-block;
                    }

                    i {
                        vertical-align: baseline;
                    }
                }
            }
        }

        .search-results {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            box-sizing: border-box;
            gap: 0.75rem;

            .element {
                border-radius: 3px;
                text-align: center;
                padding: 1rem 0.55rem;
                box-sizing: border-box;
                display: grid;
                align-items: center;
                min-height: 7rem;
                user-select: none;
                cursor: pointer;
                background-color: transparentize($brand-blue-light, 0.7);
                color: $brand-blue-light-text;
                position: relative;
                overflow: hidden;
                padding-top: 1rem;

                .recipe-icon {
                    font-size: 4rem;

                    i.icon {
                        fill: #b7dcdc4d !important;
                    }
                }

                .food-icon {
                    font-size: 4.25rem;
                    fill: transparentize(#345f1a, 0.9);
                }

                .recipe-icon,
                .food-icon {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    top: 50%;
                }

                span.name {
                    position: relative;
                    z-index: 2;
                }

                &.food {
                    background-color: transparentize($brand-green, 0.9);
                    color: $brand-green-text2;
                }

                &:hover,
                &.active {
                    box-shadow: rgba(0, 0, 0, 0.3) 0 0.2rem 0.3rem -0.1rem;
                    font-weight: bold;
                }
            }

            &.in-progress {
                .element {
                    display: none;
                }
            }
        }
    }

    .difference {
        padding-left: 0.5rem;

        &.red {
            color: $brand-red !important;
        }

        &.green {
            color: $header-bar;
        }
    }
}

@media all and (max-width: $mobile-breakpoint) {
    .replace-modal {
        .replace-header {
            min-height: 3.5rem;
        }

        .replace-inner {
            .buttons {
                grid-template-columns: 1fr;

                button {
                    text-transform: uppercase;
                }
            }

            padding: 2rem 1rem;

            .search-results {
                grid-template-columns: 1fr 1fr;
            }

            .replace-info {
                .meal-info-element {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}

@media all and (max-width: $extra-mobile-breakpoint) {
    .replace-modal {
        .replace-inner {
            .buttons {
                gap: 0.5rem;
            }

            .replace-info,
            .search-results {
                grid-template-columns: 1fr;
            }
        }
    }
}

@media print {
    .row.item {
        grid-template-columns: repeat(4, 1fr);
    }
}