@import "styles/variables.scss";

.input-search-advanced {
    position: relative;
    box-sizing: border-box;

    input::placeholder {
        user-select: none;
    }

    .loading-dots {
        padding: 1.25rem 1rem 0.5rem;
    }

    &.in-progress {
        .result-wrapper {
            .results {
                div.result-wrapper {
                    display: none;
                }
            }
        }
    }

    .options {
        display: grid;
        grid-template-columns: max-content max-content;
        gap: 1rem;
        padding-top: 0.75rem;

        .option {
            display: flex;
            align-items: center;

            cursor: pointer;
            font-size: $font-normal;
            margin-right: auto;

            .checkbox {
                font-size: $font-large;
                fill: $brand-main;
            }
        }
    }

    .result-wrapper {
        position: relative;
        box-sizing: border-box;


        * {
            scrollbar-width: thin;
        }

        *::-webkit-scrollbar {
            width: 8px;
        }

        .results {
            width: 100%;
            box-sizing: border-box;
            visibility: hidden;

            background-color: $white;
            height: 1px;
            transition: 0.3s height ease-in-out;
            font-size: $font-normal;

            &.visible {
                height: auto;
                visibility: visible;
                overflow-y: auto;
            }

            .result-caption {
                text-transform: uppercase;
                font-size: 0.9rem;
                letter-spacing: -0.1px;
                box-sizing: border-box;
                display: inline-block;
                color: #c1c1c1;
                margin: 0;
                width: 100%;
                line-height: 1;
                padding: 1.25rem 1rem 0.5rem;
            }

            .result-item {
                box-sizing: border-box;
                display: inline-block;
                width: 100%;
                cursor: pointer;
                padding: 0.25rem;

                .brand {
                    display: inline-block;
                    padding-left: .5rem;
                }

                &:focus {
                    outline: none;
                    ;
                }

                &.selected,
                &:hover {
                    background: transparentize($rte-blue, 0.65);
                }

                &.selected {
                    font-weight: bold;
                }
            }
        }
    }

    .load-more-button {
        padding-left: 2rem;
        padding-right: 2rem;

        margin: 0 auto;
        display: flex;
        margin-top: 2rem;
        max-width: 20rem;
        width: 100%;
        text-align: center;
        justify-content: center;
    }
}