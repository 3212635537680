@import "styles/variables.scss";

.week-grid.for-my-menu {
    .raw-grid .raw-grid-row {

        .item.value-item.week-grid-tile {
            .meal-override {
                .name {
                    background: transparentize($circlechart-blue, .8);
                    border: 1px solid transparentize(darken($circlechart-blue, 10%), .6);
                }

                .delete-icon {
                    border: 2px dashed darken($circlechart-blue, 10%);

                    i.icon {
                        fill: darken($circlechart-blue, 20%);
                    }
                }
            }
        }

    }
}