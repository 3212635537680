@import "styles/variables.scss";

header {
    margin: 0;
    font: inherit;
    vertical-align: bottom;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: $header;
    font-size: $font-large;
    box-sizing: border-box;
    text-align: center;
    background-size: auto 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 30%), url('~images/headerImage.png');
    grid-area: header;
    min-height: 14rem;
}

@media screen and (max-width: $mobile-breakpoint) {
    header {
        background: none;
        min-height: 6rem;
    }

    header>.logo {
        display: none;
    }
}

@media print {
    header {
        min-height: 7rem;
        background: none;
        padding-top: .5rem;
    }
}