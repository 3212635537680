$font-mono: "Ubuntu Mono";
$font: Arial, Helvetica, sans-serif;

$small-breakpoint: 1024px;
$mobile-breakpoint: 640px;
$small-mobile-breakpoint: 540px;
$extra-mobile-breakpoint: 370px;

$panel: #fff;

$background: #f9f9f9;
$brand-grey: #3b3b3b;
$border-light-grey: #e0e0e0;
$text: #000;
$text-light: #888;
$text-lighter: #bbb;
$white: #fff;
$text-accent: #2f8079;

$validation-error: rgba(220, 33, 41, 1);

$header: #fff;
$header-bar: rgb(89, 139, 53);
$header-bar-tone1: darken($header-bar, 7.5%);
$header-bar-tone2: darken($header-bar, 15%);
$border: darken($header, 12%);
$button: $header;

$modal-header-bar: #6a9a44;
$modal-collapse-bar: #879d79;
$modal-collapse-bar-active: #4a6c2f;

$brand-green: #87c45c;
$brand-green-lighten1: lighten($brand-green, 7.5%);
$brand-green-tone1: darken($brand-green, 7.5%);
$brand-green-tone2: darken($brand-green, 15%);
$brand-green-border: darken($brand-green, 10%);
$brand-green-text: #ffffff;
$brand-green-text2: #345f1a;

$brand-blue: rgb(60, 170, 167);

$brand-blue-border: darken($brand-blue, 10%);

$brand-blue2: #76a9aa;
$brand-blue2-border: darken($brand-blue2, 10%);

$brank-blue3: #278382;
$brand-blue-text: #ffffff;

$rte-blue: #b8e1e1;

$brand-blue-light: rgb(190, 228, 227);
$brand-blue-light-text: #175656;

$brand-red: rgb(218, 32, 38);
$brand-red-border: darken($brand-red, 10%);
$brand-red-text: #ffffff;

$brand-yellow: rgb(225, 215, 60);
$brand-yellow-border: darken($brand-yellow, 10%);
$brand-yellow-text: #ffffff;

$brand-gold: #f6b43a;
$brand-gold-border: darken($brand-gold, 10%);

$brand-main: $brand-green;
$brand-main-border: darken($brand-main, 10%);
$brand-main-text: #fff;

$brand-activity: #e03942;
$brand-activity-faded: #e37d8c;
$brand-activity-active: #880b11;

$font-tiny: 0.75rem;
$font-small: 1rem;
$font-normal: 1.25rem;
$font-large: 1.5rem;
$font-larger: 1.75rem;
$font-header: 2rem;

$hover-opacity: 0.85;

$link-color: $text;

$readonly: #9d9fa6;

$circlechart-green: rgb(135, 196, 92);
$circlechart-blue: rgb(60, 170, 167);
$circlechart-yellow: #e8e068;
$circlechart-red: #f38890;
$circlechart: #e7f3df;

$calendar-base-color: #3caaa7;