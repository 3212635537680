@import "styles/variables.scss";

.smartInput {
    position: relative;
}

.smartInput .smartInputResults {
    display: block;
    background: $panel;
    left: 0;
    outline: 1px $brand-main dashed;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.smartInput .smartInputResultsWrapper {
    position: absolute;
    z-index: 5;
    max-height: 50vh;
    overflow: auto;

    * {
        scrollbar-width: thin;
    }

    *::-webkit-scrollbar {
        width: 8px;
    }
}

.smartInput .smartInputResult {
    display: block;
    cursor: pointer;
}

.smartInput .smartInputResult:hover,
.smartInput .smartInputResult.selected {
    background: darken($background, 10);
}

.smartInputIndicator {
    font-size: $font-normal;
    border-left: 1px solid $border;
    padding: 0.5rem 0.5rem;
    display: block;
    box-sizing: border-box;
    line-height: 2.2rem;
    position: absolute;
    right: 0px;
    top: 0px;
}