@import "styles/variables.scss";

.container {
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;

    &.margins {
        margin: 1rem;
    }

    &.fill {
        padding: 0;
    }

    &.panel {
        background-color: $panel;
    }

    &.transparent {
        background-color: transparent !important;
    }

    &.halfSpace {
        margin-top: 0.5rem;
    }

    &.nopadding {
        padding: 0;
    }
}

.container-vscrolled {
    overflow-y: auto;
}

.container-vfull {
    max-height: calc(100vh - 8rem - 2px);
}