@import "styles/variables.scss";

.notification {
    background: #fff;
    width: 100%;
    max-width: 20rem;
    font-size: $font-small;
    border: 1px solid #888888;
    word-break: break-word;
    border-radius: .3rem;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
    padding: .5rem .5rem .5rem 1.25rem;
    position: relative;
    user-select: none;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 1fr 1.8rem;

    .text {
        display: flex;
        align-items: center;
    }

    i.icon {
        font-size: $font-large;
    }

    &.hidden-close {
        i.icon {
            display: none;
        }
    }

    &.with-error {
        color: $validation-error;
        border-color: $validation-error;
        fill: $validation-error;
    }

    opacity: 1;

    transition: .25s opacity cubic-bezier(0.075, 0.82, 0.165, 1),
    .1s transform cubic-bezier(0.075, 0.82, 0.165, 1);

    &.closing {
        opacity: 0;
        transform: scale(0.95);
    }
}