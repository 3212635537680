@import "styles/variables.scss";

.compare-item-modal {
    box-sizing: border-box;

    .compare-item-header {
        min-height: 3rem;
        background-color: $modal-header-bar;
        padding-left: 1rem;
        padding-right: 0.5rem;

        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        box-sizing: border-box;

        span {
            display: inline-block;
            padding-top: 0.2rem;
            line-height: 1;
        }

        position: relative;

        button {
            position: absolute;
            right: 0.5rem;

            padding: 0;

            span {
                i.icon {
                    padding-left: 0.5rem;

                    svg {
                        fill: $brand-main-text;
                    }
                }
            }
        }

        span,
        button {
            color: $brand-main-text;
        }
    }

    .compare-item-inner {
        background: $white;
        padding: 2rem 3rem;

        .site-name {
            text-decoration: underline;
            font-style: italic;
        }

        .buttons {
            display: grid;
            grid-template-columns: minmax(8rem, max-content) minmax(5rem, max-content);
            gap: 1rem;
            margin-left: auto;
            justify-content: flex-end;
            padding-top: 1.5rem;

            button.disabled {
                opacity: 0.4 !important;
                pointer-events: initial !important;
            }

            button.back {
                color: $text-accent !important;
                border: 1px solid;
            }
        }

        .nutrition-tables {
            position: relative;
        }

        .compare-info {
            .text-block {
                padding: 0;
            }
            display: grid;
            grid-template-columns: 1fr minmax(12rem, max-content) 1fr;
            @media all and (max-width: $mobile-breakpoint) {
                grid-template-columns: unset;
            }
            grid-auto-flow: dense;
            align-items: flex-start;
            user-select: none;
            justify-content: center;
            line-height: 2rem;
            gap: 0;
            padding-top: 0.25rem;

            .circle-chart {
                margin: 1.75rem auto 1.5rem;
            }

            .left-column {
                grid-column: 1 / 2;
                @media all and (max-width: $mobile-breakpoint) {
                    grid-column: 1 / 4;
                }
            }
            .right-column {
                grid-column: 3 / 4;
                @media all and (max-width: $mobile-breakpoint) {
                    grid-column: 1 / 4;
                }
                &.full-height {
                    grid-row: 1 / 3;
                    @media all and (max-width: $mobile-breakpoint) {
                        grid-row: unset;
                    }
                }
            }

            .center-section {
                display: grid;
                justify-items: center;
                grid-row: 1 / 3;
                grid-column: 2 / 3;
                grid-template-rows: min-content min-content;
                .legend {
                    width: 100%;
                    & > div {
                        text-align: center;
                        position: relative;

                        span {
                            position: absolute;
                            left: 0;
                            top: 0;
                            padding: 0 0.5rem;
                            min-width: 1.5rem;

                            &:nth-child(2n) {
                                right: 0;
                                left: auto;
                            }
                        }
                    }

                    .carbs {
                        background: $circlechart-green;

                        span {
                            background: lighten($circlechart-green, 10%);
                        }
                    }

                    .protein {
                        background: $circlechart-blue;

                        span {
                            background: lighten($circlechart-blue, 10%);
                        }
                    }

                    .fat {
                        background: $circlechart-yellow;

                        span {
                            background: lighten($circlechart-yellow, 10%);
                        }
                    }

                    .alcohol {
                        background: $circlechart-red;

                        span {
                            background: lighten($circlechart-red, 10%);
                        }
                    }

                    &.no-value {
                        background: $circlechart !important;

                        span {
                            background: lighten($circlechart, 10%) !important;
                        }
                    }
                }
                .compare-icon {
                    display: inline-block;
                    margin: 1rem auto;

                    i.icon {
                        font-size: 6rem;
                        fill: #c9e9e9;
                    }
                    @media all and (max-width: $mobile-breakpoint) {
                        display: none;
                    }
                }
                @media all and (max-width: $mobile-breakpoint) {
                    grid-row: unset;
                    padding: 1rem 0;
                    grid-column: 1 / 4;
                    i.icon {
                        font-size: 5rem;
                    }
                }
            }
        }

        .compare-table {
            background-color: #c9e9e9;
            padding: 0.5rem 1rem;
            margin-bottom: 1rem;

            .row {
                display: grid;
                width: 100%;
                box-sizing: border-box;
                border-bottom: 1px solid #b6d6d8;
                padding-bottom: 0.5rem;
                margin-bottom: 0.5rem;
                grid-column-gap: 0.5rem;
                grid-template-columns: 1fr 10rem 9rem 10rem;

                span {
                    font-size: 1.35rem;
                    color: #495657;
                }

                &.header {
                    padding-bottom: 0.6rem;
                }

                &.item {
                    padding-bottom: 0.6rem;
                    padding-top: 0.25rem;
                }

                &:last-child {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}

.compare-item-modal {
    @media all and (max-width: $mobile-breakpoint) {
        .compare-item-inner {
            padding: 2rem 1rem;

            .compare-info {
                gap: 1rem;
            }

            .compare-table {
                .row {
                    grid-template-columns: 2fr 1fr 1fr 1fr;
                }
            }
        }
    }

    @media all and (max-width: 470px) {
        .compare-item-inner {
            .compare-info {
                grid-template-columns: 1fr;
                text-align: center;

                .element {
                    .compare-icon {
                        display: none;
                    }
                }
            }
        }
    }
}

@media print {
    .compare-table {
        .row {
            padding-bottom: 0;
            margin-bottom: 0;
            border: 0;
        }

        .row.item {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
