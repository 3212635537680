@import "styles/variables.scss";

button,
.navlink-button {
    text-align: center;
    box-sizing: border-box;
    font-size: $font-large;
    line-height: $font-large;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
    color: $text-light;
    background-color: $header;
    display: inline-block;
    cursor: pointer;
    position: relative;
    vertical-align: top;
    border: none;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0.2rem 0.1rem -0.1rem;
    border-radius: 3px;
    overflow: hidden;

    &.spacearound {
        margin: 0.5rem;
    }

    &.block {
        width: 100%;
        display: inline-block;
    }

    &.small {
        font-size: $font-tiny;
        padding: 0.25rem 0.5rem;
    }

    &.big {
        padding: 0.75rem 2rem;
        font-size: $font-header;
        line-height: 2rem;
    }

    &.nolrpadding {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &.fill {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &.inputHeight {
        line-height: calc(1.5rem - 1px);
        font-size: 1.2rem;
    }

    &.largeHeight {
        line-height: 2rem;
    }

    &.green {
        background-color: $brand-green;
        color: $brand-green-text;
        fill: $brand-green-text;

        &.bordered {
            border-color: $brand-green-border;
        }

        &.clear {
            color: $brand-green;
        }

        &.inverted {
            background-color: $brand-green-text;
            color: $brand-green;
            fill: $brand-green;
        }
    }

    &.blue {
        background-color: $brand-blue;
        color: $brand-blue-text;
        fill: $brand-blue-text;

        &.bordered {
            border-color: $brand-blue-border;
        }

        &.clear {
            color: $brand-blue;
        }

        &.inverted {
            background-color: $brand-blue-text;
            color: $brand-blue;
            fill: $brand-blue;
        }
    }

    &.red {
        background-color: $brand-red;
        color: $brand-red-text;
        fill: $brand-red-text;

        &.bordered {
            border-color: $brand-red-border;
        }

        &.clear {
            color: $brand-red;
        }

        &.inverted {
            background-color: $brand-red-text;
            color: $brand-red;
            fill: $brand-red;
        }
    }

    &.brand-new {
        background-color: $brand-blue2;
        color: $white;
        fill: $white;

        &.bordered {
            border-color: $brand-blue2-border;
        }

        &.clear {
            color: $brand-blue2;
        }

        &.active {
            color: $brank-blue3;
            background-color: $white !important;
            box-shadow: 0px 0px 0px 1px $brank-blue3 inset;
            border-radius: 3px;
        }

        &.inverted {
            background-color: $white;
            color: $brand-blue2;
            fill: $brand-blue2;
        }
    }

    &.yellow {
        background-color: $brand-yellow;
        color: $brand-yellow-text;
        fill: $brand-yellow-text;

        &.bordered {
            border-color: $brand-yellow-border;
        }

        &.clear {
            color: $brand-yellow;
        }

        &.inverted {
            background-color: $brand-yellow-text;
            color: $brand-yellow;
            fill: $brand-yellow;
        }
    }

    &.gold {
        background-color: $brand-gold;
        color: $brand-yellow-text;
        fill: $brand-yellow-text;

        &.bordered {
            border-color: $brand-gold-border;
        }

        &.clear {
            color: $brand-yellow;
        }

        &.inverted {
            background-color: $brand-yellow-text;
            color: $brand-gold;
            fill: $brand-gold;
        }
    }

    &.clear {
        color: darken($brand-green, 30%);
        background-color: transparent;
        box-shadow: none;
    }

    &.bordered {
        border: 1px solid;
    }

    &.left {
        float: left;
    }

    &.right {
        float: right;
    }

    &.transform-normal {
        text-transform: none;
    }

    &.no-shadow {
        box-shadow: none;
    }

    &.icon-button {
        height: 5rem;
        white-space: wrap;

        i.icon {
            margin-right: .5rem;
        }

        svg {
            height: 3rem;
        }
    }

    &.disabled {
        opacity: .7;
        pointer-events: none;
        &:focus {
            outline: none;
        }
        cursor: default;
    }

    &.no-focus-outline {
        &:focus {
            outline: none;
        }
    }

    &.has-error {
        border: 1px solid $validation-error !important;
        color: $validation-error;
    }

    &.transparent {
        background-color: transparent;
    }

    &.progress {
        min-width: 8rem;

        .hidden {
            position: absolute;
            visibility: hidden;
            width: 1px;
            height: 1px;
            top: 0;
            left: 0;
        }
    }

}