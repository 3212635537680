@import "styles/variables.scss";

.shopping-item-modal {
    display: grid;
    grid-gap: 1.5rem;
    padding: 1rem 2rem;

    .has-title {
        line-height: 1.1;
        gap: .7rem;
        display: flex;
    }

    &.disabled {
        button.progress {
            opacity: 1 !important;
        }

        .input-container,
        .amount-controll {
            opacity: .4;
        }

        .amount-controll,
        .input-container input {
            user-select: none;
            pointer-events: none;
        }
    }

    .add-shopping-label {
        text-transform: capitalize;
    }

    .fill-empty-space {
        padding-top: 1.5rem;
        padding-bottom: 2.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: .5rem;

        color: #ddd;
        opacity: .8;

        span {
            text-transform: uppercase;
            font-size: $font-small;
        }

        i {
            color: #eee;
            font-size: 4rem;
            line-height: 1;
        }
    }

    .show-recipe-button {
        color: $text-accent !important;
        border: 1px solid;
        max-width: 14rem;
        margin-left: auto;
        width: 100%;
    }

    .amount-controll {
        line-height: 2.5rem;
        align-items: center;

        padding: 1rem;
        box-shadow: none;
        background: #f6fafa;
        color: #777;
        border: 1px solid #ddd;
        border-radius: 5px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        input {
            background-color: #fff;
        }

        &.is-recipe {
            grid-template-columns: 50%;
            justify-content: center;
        }
    }

    .search {
        &.visible-hidden {
            display: none;
        }
    }

    button[type='submit'] {
        margin-top: .5rem;
    }

    .selected-item {
        margin-top: 2rem;
        margin-bottom: .5rem;

        .close-icon {
            i.icon {
                cursor: pointer;
                height: 2rem;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: .8rem;
                fill: #333;
                z-index: 10;
                font-size: 2rem;
            }
        }


        span.name {
            font-size: $font-normal;
            border: 1px solid #E1E4E8;
            border-radius: 3px;
            padding: .8rem 1rem .7rem;
            display: block;
            margin: auto;
            box-sizing: border-box;
            width: 100%;
            line-height: 2rem;
            position: relative;
            background: none;
            font-family: Arial, Helvetica, sans-serif;

            display: flex;
            align-items: center;
            gap: 1rem;

            i.icon {
                display: flex;
                align-items: center;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .amount-controll {
            grid-template-columns: max-content minmax(15rem, max-content);
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 1rem .5rem;

        button {
            line-height: 2.5rem;
        }
    }
}