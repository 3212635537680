@import "styles/variables.scss";

.logout-modal {
    display: grid;
    gap: 2rem;
    align-items: center;
    height: 100%;

    .modal-content {
        display: grid;
        gap: 2rem;
    }

    .modal-footer {
        .logout-button-cancel {
            background: #F8F8F8;
            color: black;
            border: 1px solid rgba(224, 224, 224, 0.015);
            line-height: 2.25rem;
            min-width: 11rem;
        }

        button {
            line-height: 2.25rem;
        }
    }

    .logout-button-mobile {
        display: none;
        max-width: 14rem;
        width: 100%;
        padding: 2.63rem 1rem;
        margin: 0 auto 1rem;
        border-radius: 7rem;
        font-size: $font-normal;
        color: black;
        box-shadow: rgba(0, 0, 0, 0.3) 0 0.2rem 1rem -0.1rem;

        .logout-icon {
            font-size: 7rem;
            color: #6F6F6F;
            margin-bottom: .25rem;
            display: inline-block;
        }

        &:active {
            box-shadow: rgba(0, 0, 0, 0.8) 0 0.2rem 1rem -0.1rem;

            .logout-icon {
                color: $text;
            }
        }
    }

    .modal-footer {
        display: grid;
        grid-template-columns: minmax(7rem, max-content) minmax(12rem, max-content);
        justify-content: space-between;
        gap: .5rem;
    }

}

@media all and (max-width: $mobile-breakpoint) {
    .logout-modal {
        width: 14rem;
        display: block;

        .logout-button-mobile {
            display: block;
            -webkit-tap-highlight-color: transparent;
            margin: 0;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .modal-footer,
        .modal-content {
            display: none;
        }
    }
}