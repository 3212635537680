@import "styles/variables.scss";

@page {
    size: A4;
    margin: 0;
}

@media print {

    html,
    body {
        width: 210mm;
        height: 297mm;
        font-size: 10px !important;
    }

    /* ... the rest of the rules ... */
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes tileFade {
    from {
        opacity: 1;
    }

    to {
        opacity: .3;
    }
}

@keyframes tableFade {
    from {
        opacity: 1;
    }

    to {
        opacity: .7;
    }
}


/*--keyframe pointer --*/
.fadeIn {
    animation-duration: 800ms;
    animation-fill-mode: both;
    animation-name: fadeInFromNone;
    animation-delay: 50ms;

    &.fast {
        animation-duration: 50ms;
        animation-delay: 50ms;
    }

    &.one {
        animation-delay: 50ms;
    }

    &.two {
        animation-delay: 150ms;
    }
}

.appWrapper {
    width: 100%;
    margin: auto;
    min-height: 100vh;
    position: relative;
    background: $header;
    display: grid;
    row-gap: 0;
    column-gap: 0;
    grid-template: "header"14rem "topbar"3.5rem "content"1fr "empowered-logo"auto "footer"max-content "footer-copy"4rem / 100%;

    @media print {
        grid-template: "header"7rem "topbar"3rem "content"1fr "empowered-logo"auto "footer"0 "footer-copy"4rem / 100%;
    }

    &.with-calendar {
        grid-template: "header"14rem "topbar"3.5rem "calendar"3.5rem "content"1fr "empowered-logo"auto "footer"max-content "footer-copy"4rem / 100%;

        @media print {
            grid-template: "header"7rem "topbar"3rem "calendar"3.5rem "content"1fr "empowered-logo"auto "footer"0 "footer-copy"4rem / 100%;
        }
    }

    &.not-logged {
        grid-template:
            "header"12rem "topbar"3.5rem "content"1fr "empowered-logo"auto "footer"max-content "footer-copy"4rem / 100%;

        &.with-calendar {
            grid-template:
                "header"12rem "topbar"3.5rem "calendar"3.5rem "content"1fr "empowered-logo"auto "footer"max-content "footer-copy"4rem / 100%;
        }

        header {
            min-height: 12rem;

            .logo img {
                max-height: 5rem;
                margin-top: 2rem;
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            grid-template:
                "header"6rem "topbar"3.5rem "content"1fr "empowered-logo"auto "footer"0 "footer-copy"0;


            &.with-calendar {
                grid-template:
                    "header"6rem "topbar"3.5rem "calendar"3.5rem "content"1fr "empowered-logo"auto "footer"0 "footer-copy"0;
            }

            header {
                min-height: 6rem;
                padding-top: 0;

                .logo img {
                    max-height: 3.75rem;
                    margin-top: auto;
                }
            }

        }
    }

    &.is-client-profile {
        box-sizing: border-box;
        border-left: 1rem solid yellow;
        border-right: 1rem solid yellow;
        border-bottom: 1rem solid yellow;
    }

    // &.is-admin {
    //     padding-top: 2.6rem;
    // }

    header {
        padding-top: 4.5rem;

        .header-button {
            width: 8rem;

            @media screen and (max-width: $small-breakpoint) {
                width: 7rem;
            }
        }
    }

    .logoContainer.empowered {
        grid-area: empowered-logo;
        text-align: right;
        box-sizing: border-box;
        width: 100%;
        padding: 0 3.5rem .5rem;
        margin: auto auto 0;

        img {
            max-height: 5rem;
        }

        @media screen and (max-width: 100rem) {
            padding: 1rem 2rem .5rem;
        }

        @media screen and (max-width: $mobile-breakpoint) {
            padding-bottom: 5rem;

        }
    }
}

.calendar {
    display: block;
    background-color: none;
    grid-area: calendar;
    text-align: center;

    .calendar-container {
        margin: auto;
        width: 7*8rem;

        .calendar-day {
            box-sizing: border-box;
            display: inline-block;
            width: 8rem;
            height: 3.75rem;
            font-size: $font-large;
            color: lighten($brand-green, 30%);
            font-weight: bold;
            padding-top: 0.25rem;
            border-bottom: 0.5rem $brand-green solid;
            border-left: 0.125rem $brand-green solid;
            border-right: 0.125rem $brand-green solid;

            &:first-child {
                border-left: 0.5rem $brand-green solid;
                border-bottom-left-radius: 0.5rem;
            }

            &:last-child {
                border-right: 0.5rem $brand-green solid;
                border-bottom-right-radius: 0.5rem;
            }

            .small {
                font-size: 1.2rem;
                display: block;
            }

            &.clickable {
                color: darken($brand-green, 30%);
            }

            &.middle {
                height: 4rem;
                border: 0.5rem $header-bar solid;
                border-bottom-width: 0.75rem;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                border-top: none;
            }

            &.today {
                color: $brand-red;
            }

            &.offWeek {
                border-color: lighten($brand-green, 10%);
            }
        }

    }

    @media screen and (max-width: $small-breakpoint) {
        .calendar-container {
            width: 7*7rem;

            .calendar-day {
                width: 7rem;
            }
        }
    }


    @media screen and (max-width: $mobile-breakpoint) {
        padding: 0 !important;

        .calendar-container {
            width: 100%;

            .calendar-day {
                width: 6rem;
                font-size: $font-normal;

                .small {
                    font-size: $font-small;
                }
            }
        }
    }

    @media screen and (max-width: 510px) {
        .calendar-container {
            .calendar-day {
                width: 4.4rem;

                font-size: 1.1rem;

                .small {
                    font-size: .9rem;
                }

                &.middle {
                    border-right-width: .3rem !important;
                    border-left-width: .3rem !important;
                }
            }
        }
    }

    @media screen and (min-width: $extra-mobile-breakpoint) and (max-width: 510px) {
        .calendar-container {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            max-width: 39rem;

            .calendar-day {
                width: auto;

                &.middle {
                    width: 5.84rem;
                    font-size: 1.3rem !important;
                }
            }
        }

    }

    @media screen and (max-width: $extra-mobile-breakpoint) {
        .calendar-container {
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            .calendar-day {
                width: auto;
                font-size: 1rem;

                border-right-width: 1px !important;
                border-left: none;

                &.middle {
                    border-right-width: 0.2rem !important;
                    border-left-width: .2rem !important;
                }

                &:first-child {
                    border-left: none;
                    border-radius: 0;
                }

                &:last-child {
                    border-right: none;
                    border-radius: 0;
                }
            }
        }
    }
}


.content {
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    grid-area: content;
    padding: 2rem;
    box-sizing: border-box;

    &.full-width {
        max-width: none;
    }

    &.no-padding {
        padding: 0 !important;
    }

    &.logo-hidden~.logoContainer.empowered {
        display: none;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 2rem 1rem;
    }

    @media print {
        padding: .75rem !important;
    }
}

.logoContainer {
    width: 100%;
    display: block;
}

.account-setup-screen {
    display: grid;
    grid-template-rows: 0rem auto 1fr;
    height: 100vh;
    box-sizing: border-box;
    margin: auto;

    &.fetching {
        overflow: hidden;
    }

    .main-logo {
        background: url('~images/loginPage_desktop.jpg') right center no-repeat;
        background-size: cover;
        text-align: center;
        color: #6dae3f;
        font-size: 4rem;
        text-align: center;
        line-height: 4rem;
        padding-top: 5rem;
        padding-bottom: 2.5rem;

        img {
            max-width: 30rem;
            box-sizing: border-box;
            width: 100%;
        }

        @media screen and (max-width: $mobile-breakpoint) {
            background: url('~images/loginPage_mobile.jpg') right center no-repeat;

            img {
                padding: 0 1rem;
            }
        }
    }

    .account-setup {
        .container {
            height: 100%;

            .wizard {
                box-shadow: 0px 1rem 3rem 0px rgba(0, 0, 0, 0.12);
                gap: 1rem;
                height: 100%;
                display: grid;
                grid-template-rows: auto 1fr auto;
                box-sizing: border-box;

                &.finished {
                    grid-template-rows: none;
                    align-items: flex-start;
                }
            }
        }
    }

    .account-setup-footer {
        button {
            background-color: $header-bar;
        }
    }
}

@media screen and (min-width: $small-breakpoint) {
    html {
        font-size: 75% !important;
    }
}

@media screen and (min-width: $mobile-breakpoint) and (max-width: $small-breakpoint) {
    html {
        font-size: 75% !important;
    }

    .appWrapper {
        grid-template: "header"12rem "topbar"3.5rem "content"1fr "empowered-logo"auto "footer"max-content "footer-copy"4rem / 100%;

        header {
            padding-top: 3rem;
            min-height: 12rem;
        }

        &.with-calendar {
            grid-template: "header"12rem "topbar"3.5rem "calendar"3.5rem "content"1fr "empowered-logo"auto "footer"max-content "footer-copy"4rem / 100%;
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    html {
        font-size: 75% !important;
    }

    .appWrapper {
        grid-template: "header"6rem "topbar"3.5rem "content"1fr "empowered-logo"auto "footer"0 "footer-copy"0;

        header {
            padding-top: 0;
            min-height: 6rem;
        }

        &.with-calendar {
            grid-template: "header"6rem "topbar"3.5rem "calendar"3.5rem "content"1fr "empowered-logo"auto "footer"0 "footer-copy"0;
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .appWrapper {
        grid-template: "header"7rem "topbar"3.5rem "content"1fr "empowered-logo"auto "footer"0 "footer-copy"0;

        header {
            &.version-hidden {
                &:after {
                    display: none;
                }
            }
        }

        &.with-calendar {
            grid-template: "header"7rem "topbar"3.5rem "calendar"3.5rem "content"1fr "empowered-logo"auto "footer"0 "footer-copy"0;
        }
    }
}

.nutritionHider {
    display: block;
    position: relative;
    overflow: visible !important;
}

.nutritionHider .nutritionHidden {
    z-index: 1;
    display: none;
    position: absolute;
    top: 3rem;
    width: 15rem;
    text-align: right;
    background: #FFF;
    color: #000;
    border: 2px solid #777;
    border-radius: 1rem;
    padding: 1rem;
    font-size: 1rem;
}

.nutritionHider .nutritionHidden b {
    float: left;
}

.nutritionHider:hover .nutritionHidden {
    display: block;
}

.fullContent {
    max-width: none;
}

.grid-7 {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
}

.rte-color {
    color: transparentize($brand-blue, 0.2) !important
}

.recipe-color {
    color: transparentize($brand-red, 0.2) !important;
}

.rte-icon {
    i.icon {
        fill: transparentize($brand-blue, 0.2) !important;
        color: transparentize($brand-blue, 0.2) !important
    }
}

.recipe-icon {
    i.icon {
        fill: transparentize($brand-red, 0.2) !important;
        color: transparentize($brand-red, 0.2) !important;
    }
}

.home-week-calendar {
    grid-template-columns: 1fr;
    display: grid;
    gap: .5rem;
    padding-bottom: 1rem;
    align-items: center;

    .calendar-day {
        line-height: 3rem;
        font-size: $font-header;
        color: $brand-blue;
        font-weight: bold;
    }

    .week-calendar {
        padding: 0;
        grid-area: unset;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        grid-template-columns: 1fr;
        padding-bottom: 0;
    }
}


.headerBar {
    user-select: none;

    .header-bar-button,
    .tooltip {
        z-index: 2;
        position: relative;
    }

    .header-bar-caption {
        z-index: 1;
    }


    .help-center-link {
        width: auto;


        a {
            display: grid;

            &,
            i.icon {
                color: #fff;
                fill: #fff;
            }

        }

        &:hover,
        &.active {

            .text,
            a i.icon {
                color: lighten($brand-main, 10%);
                fill: lighten($brand-main, 10%);
            }
        }

        .text {
            font-size: $font-normal;
            text-transform: none;
        }

        &.with-text {
            @media screen and (max-width: $mobile-breakpoint) {
                padding: .75rem .25rem;
                gap: .05rem;

                .text {
                    text-transform: capitalize;
                }
            }
        }
    }

    .help-video-link {
        position: absolute;
        font-size: $font-normal;
        left: 8.275rem;
        width: auto;
        top: 0;
        text-transform: none;
        height: 100%;
        box-sizing: border-box;
        display: inline-block;
        padding: .75rem;

        @media screen and (max-width: $mobile-breakpoint) {
            text-transform: capitalize;
            padding: .75rem .25rem;
            left: 7.2rem;
        }

        @media screen and (max-width: 425px) {
            font-size: $font-small;
            padding: .75rem .4rem;
            left: 6.2rem;
        }
    }
}


.disclaimer {
    box-sizing: border-box;
    width: 100%;
    max-width: 60rem;
    font-size: 1.5rem;
    padding: 0.5rem;
    border: 1px solid red;
    background: #FDD;
    color: #F00;
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.std-foodcandidate {
    color: red;
}

.no-data-message {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
}

.beta {
    position: absolute;
    display: block;
    background: $brand-main solid;
    top: 0;
    left: -0.5rem;
    margin-left: -50%;
    font-size: 1.2rem;
    padding-left: 0.5rem;
    //transform: rotate(-15deg);
    letter-spacing: 1px;
    text-transform: uppercase;
}

.manager-help-link {
    background-color: $header-bar;
    position: absolute;
    left: 1rem;
    bottom: -3.75rem;
}

.admin-panel {
    top: 0;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    padding: .5rem 1.25rem;
    box-sizing: border-box;
    background-color: #f5f5f5;

    a {
        background-color: rgba(121, 189, 73, 0.95) !important;
        color: #f5f5f5;

        &:hover {
            opacity: .8;
        }
    }
}