@import "styles/variables.scss";

.settings-pattern-modal {
    padding: 1rem 1rem 0 1rem;

    .settings-pattern {
        .settings-content {
            padding-top: 1rem;
        }
    }
}