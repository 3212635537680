@import "styles/variables.scss";

.footer-content {
    grid-area: footer;
    background-color: $header-bar;
    line-height: 5rem;
}

.footer-copy {
    grid-area: footer-copy;
    background-color: desaturate(darken($header-bar, 2.5%),5%);
    line-height: 4rem;
    height: 4rem;
    color: $brand-main-text;
    text-align: center;
}

@media screen and (max-width: $mobile-breakpoint) {
    .footer-content {
        display: none;
    }

    .footer-copy {
        display: none;
    }
}

@media print {
    .footer-content {
        display: none;
    }
}