@import "styles/variables.scss";

.footer-copy-button {
    font-size: $font-normal;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    color: #fff;

    &.active {
        cursor: default;
        color: $brand-green;
        text-shadow: $text;

        &:hover {
            color: $brand-green !important;
        }
    }

    &:hover {
        color: lighten($brand-green, 20%);
    }
}