@import "styles/variables.scss";

input, .input-disabled, .input-overlay, .input-container .backtext, label, .smartInput .smartInputResult {
  font-size: $font-normal;
  border: 1px solid #E1E4E8;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  display: block;
  margin: auto;
  box-sizing: border-box;
  width: 100%;
  line-height: 2rem;
  position: relative;
  background: none;
  font-family: Arial, Helvetica, sans-serif;
}

input[type='number']{
  padding-right: 2px;
}

input, .input-disabled {
  border: 1px solid $border;
}

input:focus {
  outline: 1px $brand-main dashed;
}

input::placeholder {
  color: $text-lighter;
}

.input-overlay {
  border: 1px transparent $border;  
  color: $text-lighter; 
}

.input-overlay .red {
  color: $brand-red; 
}

input.border-red, .input-disabled.border-red {
  border: 1px solid $brand-red;
}

input.monospace, .input-container .monospace, .DayPickerInput input {
  font-family: $font-mono, monospace;
  font-weight: bold;
}

.input-container {
  position: relative;
}

.input-container.inline {
    display: inline-block;
}

.input-container.label,
.label-relative {
    margin-top: 1.25rem;
}

.input-container .backtext {
  color: $text-lighter;
  white-space: pre-wrap;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
}

.input-container.left-icon input {
  padding-left: 4rem;
}

.input-container.left-icon > svg,
.input-container.left-icon .relative > svg {
  height: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 1rem;
  fill: $brand-green;
  z-index: 10;
}

.input-container.right-icon input {
    padding-right: 4rem;
}
  
.input-container.right-icon > svg,
.input-container.right-icon .relative > svg {
    height: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 1rem;
    fill: $brand-green;
    z-index: 10;
}

.input-container.input-disabled {
    &.right-icon > svg,
    &.right-icon .relative > svg,
    &.left-icon > svg,
    &.left-icon .relative > svg {
        fill: #e0e0e0;
    }
}

.input-container.bordered {
    border: 1px solid $border !important;
}

.datepicker-container {
  position: relative;
}

.datepicker-button {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0.5rem 0.5rem;
  line-height: 1.5rem;
  border-left: 1px solid $border;
  cursor: pointer;
}

.datepicker-dropdown-container {
  top: 2.5rem;
  right: 0px;
  position: absolute;
  border: 1px solid $border;
  padding: 0.5rem;
  background: white;
}

.input-container.has-error {
  svg {
    fill: rgba(220, 33, 41, 1) !important;
  }
  input {
    border: 1px solid rgba(220, 33, 41, 1);
    position: relative;
  }
  .validation-error {
    color: rgba(220, 33, 41, 1);
    text-align: right;
    padding-top: .3rem;
  }
}

input.apperance-hidden {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
}

.relative {
  position: relative;
}