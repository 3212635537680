@import "styles/variables.scss";

.header-bar-caption {
    line-height: 3.5rem;
    font-size: $font-header;
    color: $brand-main-text;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;

    &.red {
        background-color: $brand-red;
    }

    @media all and (max-width: $mobile-breakpoint) {
        font-size: $font-large;
    }

    @media print {
        line-height: 3rem;
        font-size: 1.75rem;
    }
}