@import "styles/variables.scss";

.collapse {
    border-radius: 4px;
    .collapse-button {
        position: relative;

        button {
            padding: 0.25rem .75rem;
            height: 3.25rem;
            white-space: wrap;

            @media all and (max-width: $mobile-breakpoint) {
                padding-right: 6rem;
                white-space: wrap;
                line-height: 2rem;
                min-height: 3.25rem;
                height: auto;
                text-align: left;
            }
        }

        i.icon {
            cursor: pointer;
            font-size: 3rem;
        }

        .help {
            cursor: pointer;
            position: absolute;
            top: .9rem;
            right: 4.25rem;
            fill: $white;

            i.icon {
                font-size: $font-large;
                height: auto;
            }
        }

        .arrow {
            cursor: pointer;
            position: absolute;
            top: .9rem;
            right: 1.5rem;

            i.icon {
                fill: $white;
                font-size: $font-large;
            }
        }
    }

    .collapse-content {
        height: 0;
        overflow: hidden;
        transition: .025s height ease-in;
        padding: 0;

        &.open {
            height: 100%;
            padding: 1rem;
        }
    }

    &.text-left {
        .collapse-button button {
            text-align: left;
        }
    }

    &.no-padding {
        .collapse-content.open {
            padding: 0 !important;
        }
    }

    &.inline-block {
        display: inline-block
    }

    @media screen and (max-width: $small-breakpoint) {
        .collapse-content.open {
            padding: 1rem 0;
        }
    }
}