@import "styles/variables.scss";

.micro-nutrients {
    .clear-btn {
        background: #f8f8f8;
        border-color: #e0e0e0;
        color: #333;
        display: none;
    }

    select.capitalize {
        text-transform: capitalize;

        option {
            text-transform: capitalize;
        }
    }

    .extra-micro-info {
        margin: 0 auto;
        width: 100%;
        display: block;
        line-height: 1.7rem;
        box-sizing: border-box;
        padding: 1rem;
        font-size: 1.4rem;
    }

    .mn-table {
        display: grid;
        grid-template-columns: 15rem auto;
        grid-template-areas:
            ". select"
            "vits data";
        gap: 1rem;
        font-size: $font-normal;

        .time-selector {
            grid-area: select;
        }

        .vitamin-selector {
            grid-area: vits;

            & > div {
                text-align: center;
                min-height: 2rem;
                line-height: 2rem;
                font-weight: bold;
                background: lighten($brand-green, 25%);
                margin-bottom: 0.5rem;
                cursor: pointer;
            }

            & > div.header {
                color: darken($brand-green, 10%);
                background: transparent;
                text-transform: uppercase;
            }

            & > div.selected {
                color: $brand-blue-text;
                background: $brand-blue;
            }
        }

        .details {
            grid-area: data;
            background: lighten($brand-green, 35%);
            padding: 0.75rem;

            table {
                user-select: none;
                width: 100%;

                .left {
                    text-align: left;
                }

                .center {
                    text-align: center;
                }

                .right {
                    text-align: right;
                }

                tr td {
                    text-align: right;

                    &:first-child {
                        text-align: left;
                        color: $text-accent;
                        font-weight: bold;
                    }
                }

                tr {
                    height: 2rem;

                    &.nutrients-section {
                        td {
                            color: #6dae3f;
                            text-transform: uppercase;
                            cursor: default;
                        }

                        cursor: default;

                        &:hover {
                            background: transparent;
                        }
                    }
                }

                td {
                    padding: 0.4rem;
                }

                thead tr {
                    background: lighten($brand-green, 15%);

                    th {
                        word-wrap: break-word;
                        padding: 0.4rem;
                        box-sizing: border-box;
                        text-align: center;
                        vertical-align: middle;
                    }
                }
            }

            &.clickable {
                table {
                    tbody {
                        tr {
                            cursor: pointer;

                            &:hover {
                                background-color: lighten($brand-green, 30%);
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: $small-breakpoint) {
        .mn-table {
            grid-template-columns: 12rem auto;

            .details {
                overflow: auto;
            }
        }
    }

    @media all and (max-width: $mobile-breakpoint) {
        .clear-btn {
            display: inline-block;
        }

        &.right-hidden {
            .mn-table {
                gap: 0.5rem 0;

                .vitamin-selector {
                    width: 0;
                    overflow: hidden;
                    visibility: hidden;
                }
            }
        }

        .mn-table {
            transition: all 0.1s ease-in-out;
            grid-template-columns: 1fr;
            gap: 0.5rem;

            .details {
                overflow: auto;
            }
        }
    }
}
