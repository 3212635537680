@import "styles/variables.scss";

.settings-menu {
    .content-size {
        padding-top: 2rem;
    }

    .collapse-has-error {
        .collapse .collapse-button {
            button {
                border: 1px solid $validation-error;
                background: #fff;
                color: $validation-error;
            }

            i.icon svg {
                fill: $validation-error !important;
            }
        }

        .validation-error {
            color: rgba(220, 33, 41, 1);
            text-align: right;
            padding-top: 0.3rem;
        }
    }

    .customizable-menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;

        @media all and (max-width: $extra-mobile-breakpoint) {
            grid-template-columns: 1fr;

            button {
                word-break: break-all;
            }
        }

        .info {
            cursor: pointer;
            fill: lightblue;

            i.icon {
                margin-right: 1rem;
                font-size: $font-large;
                height: auto;
            }
        }

        i.icon {
            line-height: 1;
        }
    }

    .snack-times {
        text-align: center;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        max-width: 40rem;
        margin: 0 auto;

        .checkbox {
            font-size: $font-large;
            color: $text-accent;
            cursor: pointer;
            user-select: none;

            i.icon {
                font-size: $font-large;
                fill: $text-accent;
                margin: 0 0.5rem;
            }
        }

        @media all and (max-width: $mobile-breakpoint) {
            grid-template-columns: auto;
            justify-items: start;

            .checkbox {
                text-align: left;
                margin-bottom: 0.75rem;
            }
        }
    }

    .likes-dislikes {
        text-align: center;

        .switch-buttons {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 3px;
        }

        .dm-select {
            grid-column-gap: 2rem;
            display: grid;
            grid-template-columns: 1fr 1fr;

            select,
            input {
                text-align-last: center;
                font-size: $font-large;
            }
        }

        .add-btn {
            max-width: 40rem;
            margin: 0 auto;
            display: block;
            line-height: 2rem;

            &.disabled {
                background-color: darken(#76a9aa, 20%);
                transition: 1s all;
            }
        }
    }

    .settings-menu-action {
        width: 100%;
        box-sizing: border-box;

        button {
            max-width: 50rem;
            margin: 0 auto;
            display: block;
        }
    }

    .flip-horizontally svg {
        transform: scale(-1, 1);
    }
}