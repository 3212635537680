@import "styles/variables.scss";

.topleft-legend-charts {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 1rem;
    max-width: 30rem;
    width: 100%;
    margin: 0 auto;

    &.extra {
        .nutrients {
            margin: auto auto auto 0;
        }
    }

    .right-column {
        display: grid;
        align-items: center;
    }

    .calendar-day {
        grid-column: span 2;
        font-weight: bold;
        color: darken($brand-green, 20%);
        margin-bottom: .5rem;

        line-height: 3rem;
        font-size: $font-header;
        text-align: center;
    }

    .nutrients {
        display: grid;
        grid-template-columns: 1fr;
        gap: .75rem;
        margin: 0 auto;
        align-self: center;

        .nutrients-tint {
            display: grid;
            grid-template-columns: max-content auto;
            align-items: center;
            gap: 1rem;

            .percentage {
                display: inline-block;
                color: black;
                padding-right: .5rem;
            }

            &.green {
                color: $brand-green;

                .square {
                    background: $brand-green;
                }
            }

            &.blue {
                color: $brand-blue;

                .square {
                    background: $brand-blue;
                }
            }

            &.yellow {
                color: $brand-yellow;

                .square {
                    background: $brand-yellow;
                }
            }

            &.reddish {
                color: #F38890;

                .square {
                    background: #F38890;
                }
            }

            .square {
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }


    @media screen and (max-width: $mobile-breakpoint) {
        gap: 0 2rem;
        .calendar-day {
            margin-bottom: 1rem;
        }
    }

    @media screen and (max-width: $extra-mobile-breakpoint) {
        gap: 0;
    }
}

.topleft-charts {
    min-height: 14rem;
    display: grid;
    grid-template-columns: 10rem auto;
    grid-template-rows: 3rem 10rem;
    grid-template-areas:
        "calendar-day calendar"
        "circlechart nutrients";
    gap: 1rem;

    &.extra {
        grid-template-areas:
            "calendar-day calendar"
            "circlechart nutrients"
            "circlechart extra";

        button {
            margin-bottom: 0;
        }
    }

    &.offCalendar {
        grid-template-rows: 10rem;
        grid-template-areas: "circlechart nutrients";
        min-height: auto;
        margin-bottom: 2rem;
    }

    .extra {
        grid-area: extra;
        padding: 0 2rem;
    }

    .circle-chart {
        grid-area: circlechart;
    }

    .calendar-day {
        grid-area: calendar-day;
        height: 3rem;
        line-height: 3rem;
        font-size: $font-header;
        font-weight: bold;
        text-align: center;
        color: $brand-blue;
    }

    .nutrients {
        grid-area: nutrients;
        position: relative;

        .nutrients-tint {
            background: lighten($brand-green, 35%);
            width: 100%;
            height: 100%;
            padding: 0.5rem 1rem 1rem 1rem;
            position: relative;
            box-sizing: border-box;

            table {
                width: 100%;
                height: 100%;
                line-height: 1.3rem;
                font-size: 1.2rem;

                th {
                    text-align: center;
                    vertical-align: top;
                    line-height: 1.5rem;
                    font-size: 1.4rem;
                }

                tr td:first-child {
                    text-align: left;
                    text-transform: capitalize;
                    padding-left: 2rem;
                }

                td,
                th {
                    text-align: center;
                    vertical-align: middle;
                }

                tr {
                    &.green {
                        background: lighten($brand-green, 10%);
                    }

                    &.blue {
                        background: lighten($brand-blue, 10%);
                    }

                    &.yellow {
                        background: lighten($brand-yellow, 10%);
                    }

                    &.reddish {
                        background: #F38890;
                    }

                    &.white {
                        background: #FFF;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $small-breakpoint) {
    .topleft-charts .nutrients {
        grid-area: nutrients;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media screen and (max-width: $small-breakpoint) {
    .topleft-charts .extra {
        padding: 0;
        font-size: $font-small;
    }

    .topleft-charts .week-calendar {
        padding: 0;
    }
}

@media all and (max-width: $mobile-breakpoint) {
    .topleft-charts {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
            "calendar-day"
            "calendar"
            "circlechart"
            "nutrients";
        justify-self: center;
        width: 100%;
    }

    .topleft-charts .circle-chart {
        margin: auto;
    }

    .topleft-charts.offCalendar {
        grid-template-columns: auto 1fr;

        .circle-chart {
            margin: 0;
        }
    }
}

@media all and (max-width: $extra-mobile-breakpoint) {
    .topleft-charts .week-calendar svg {
        height: 1rem;
    }

    .topleft-charts {

        &,
        .nutrients {
            overflow: auto;
        }
    }
}

@media all and (max-width: $small-breakpoint) {
    .topleft-charts .nutrients {
        padding: 0 !important;
    }

    .topleft-charts.extra {
        grid-template-areas:
            "calendar calendar"
            "calendar-day calendar-day"
            "circlechart nutrients"
            "extra extra";
        grid-template-columns: 1fr;
        gap: 0.25rem;
        grid-template-rows: max-content;
        grid-template-columns: min-content;

        .extra {
            margin: 0.5rem 0.5rem 0;
        }
    }
}

@media all and (min-width: 700px) and (max-width: $small-breakpoint) {
    .topleft-charts {
        gap: 1rem 2rem !important;
    }

    .topleft-charts.extra {
        .week-calendar {
            margin-bottom: 0.25rem;
        }

        .circle-chart {
            width: 8rem;
            height: 8rem;

            .calendar-day {
                height: 2.5rem;
                line-height: 2.5rem;
            }

            svg {
                height: 8rem;
            }

            .textContainer {
                width: 8rem;
                height: 8rem;
            }
        }
    }
}

@media all and (max-width: $mobile-breakpoint) {
    .topleft-charts {
        gap: 0.5rem 1rem !important;
    }

    .topleft-charts.extra {
        gap: 0.25rem !important;
        grid-template-rows: min-content min-content;

        .extra {
            margin: 0.25rem !important;
        }
    }
}

@media all and (max-width: 470px) {
    .topleft-charts.extra {
        .circle-chart {
            width: 8rem;
            height: 8rem;

            .calendar-day {
                height: 2.5rem;
                line-height: 2.5rem;
            }

            svg {
                height: 8rem;
            }

            .textContainer {
                width: 8rem;
                height: 8rem;
            }
        }
    }
}