/* This stylesheet generated by Transfonter (https://transfonter.org) on August 18, 2017 9:26 PM */

@font-face {
	font-family: 'Ubuntu Mono';
	src: url('UbuntuMono-Regular.eot');
	src: url('UbuntuMono-Regular.eot?#iefix') format('embedded-opentype'),
		url('UbuntuMono-Regular.woff') format('woff'),
		url('UbuntuMono-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Ubuntu Mono';
	src: url('UbuntuMono-Bold.eot');
	src: url('UbuntuMono-Bold.eot?#iefix') format('embedded-opentype'),
		url('UbuntuMono-Bold.woff') format('woff'),
		url('UbuntuMono-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Ubuntu Mono';
	src: url('UbuntuMono-Italic.eot');
	src: url('UbuntuMono-Italic.eot?#iefix') format('embedded-opentype'),
		url('UbuntuMono-Italic.woff') format('woff'),
		url('UbuntuMono-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('Ubuntu-Bold.eot');
	src: url('Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
		url('Ubuntu-Bold.woff') format('woff'),
		url('Ubuntu-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('Ubuntu-BoldItalic.eot');
	src: url('Ubuntu-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('Ubuntu-BoldItalic.woff') format('woff'),
		url('Ubuntu-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('Ubuntu-Regular.eot');
	src: url('Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
		url('Ubuntu-Regular.woff') format('woff'),
		url('Ubuntu-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('Ubuntu-Italic.eot');
	src: url('Ubuntu-Italic.eot?#iefix') format('embedded-opentype'),
		url('Ubuntu-Italic.woff') format('woff'),
		url('Ubuntu-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Ubuntu Mono';
	src: url('UbuntuMono-BoldItalic.eot');
	src: url('UbuntuMono-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('UbuntuMono-BoldItalic.woff') format('woff'),
		url('UbuntuMono-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
