@import "styles/variables.scss";

.header-button.left {
    float: left;
}

.header-button.right {
    float: right;
}

.header-button {
    user-select: none;
    font-size: $font-normal;
    fill: $brand-main;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    color: $text;
    overflow: hidden;

    box-sizing: border-box;
    width: 7rem;

    @media print {
        display: none;
    }

    &>span {
        display: block;
    }

    &.active {
        cursor: default;
        fill: $brand-red;
        color: $brand-red;

        i.fa,
        i.fas,
        i.fal,
        i.fad, i.fa-duotone {
            color: $brand-red !important;
        }

        &:hover {
            fill: $brand-red !important;
            color: $brand-red !important;

            i.fa,
            i.fas,
            i.fal,
            i.fad, i.fa-duotone {
                color: $brand-red !important;
            }
        }
    }

    &:hover {
        fill: lighten($brand-main, 10%) !important;
        color: lighten($text, 10%) !important;

        i.fa,
        i.fas,
        i.fal,
        i.fad, i.fa-duotone {
            color: lighten($brand-main, 10%) !important;
        }
    }

    i.icon {
        display: block;

        svg {
            height: 5rem;
        }
    }

    i.fa,
    i.fas,
    i.fal,
    i.fad, i.fa-duotone {
        color: $brand-main;
        line-height: 5rem;
        font-size: 5rem;
    }
}

// @media screen and (min-width: $mobile-breakpoint) {
//     .header-button {
//         width: 7rem;
//         padding: 0;
//     }
// }

@media screen and (max-width: $mobile-breakpoint) {
    .header-button {
        padding: .5rem 0;
        font-size: 1.06rem;
        width: 6rem !important;

        i.icon svg {
            height: 3rem;
        }

        i.fa {
            line-height: 3.6rem;
            font-size: 3rem;
        }
    }
}

@media all and (max-width: $extra-mobile-breakpoint) {
    .header-button {
        font-size: .95rem;
        padding: .55rem;
        width: auto !important;
    }
}

@media print {
    .header-button {
        padding: 0.5rem 0.5rem;
        font-size: $font-tiny;

        i.icon svg {
            height: 3rem;
        }
    }
}