@import "styles/variables.scss";

.info-modal {
    display: grid;
    grid-gap: 1.5rem;
    padding: 1rem;

    .description {
        line-height: 1.8rem;
    }

    .icon-blue {
        fill: #ADD8E6;
        color: #ADD8E6;
    }

    .icon-red {
        fill: $brand-red;
        color: $brand-red;
    }

    span.text-with-icon {
        .icon-blue {
            display: inline-block;
            font-size: 1.8rem;
        }
    }

    span.has-title {
        line-height: 1.1;

        i.icon {
            margin-right: .5rem;
        }
    }
}