@import "styles/variables.scss";

.nutrients-modal {
    padding: 1rem 1rem 0 1rem;
    display: grid;
    gap: 2rem;
    box-sizing: border-box;

    .nutrients-modal-title {
        line-height: 2rem;
        padding-right: 2rem;
    }

    @media all and (max-width: $small-breakpoint) {
        padding: 0;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        gap: 1rem;
    }

    @media all and (max-width: $extra-mobile-breakpoint) {
        .topleft-charts.offCalendar {
            grid-template-columns: 1fr !important;
            grid-template-areas: "circlechart""nutrients";
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            gap: .5rem;

            .circle-chart {
                margin: 0 auto;
            }
        }
    }
}