@import "styles/variables.scss";

.circle-chart svg {
  height: 10rem;
  fill: none;
  stroke-width: 14px;
  stroke-linecap: butt;
}

.circle-chart .textContainer {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  display: table;
  font-size: 1.2rem;
}

.circle-chart .textContainer .text {
  display: table-cell; 
  vertical-align: middle; 
  text-align: center; 
  color: $header-bar-tone1;
}

.circle-chart .text .big {
  display: block;
  font-size: $font-header;
  font-weight: bold;
  line-height: 2rem;
}

.circle-chart .text .small {
  display: block;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.circle-chart {
  position: relative;
  width: 10rem;
  height: 10rem;
  display: block;
}