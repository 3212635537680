@import "styles/variables.scss";

.table {
    width: 100%;
    font-size: $font-large;
    position: relative;

}

.table th {
    background: lighten($brand-green, 30%);
    color: $header-bar;
    padding: 0.5rem 0.5rem;
    font-weight: bold;
    white-space: nowrap;

    @media all and (max-width: $mobile-breakpoint) {
        white-space: normal;
        vertical-align: middle;
    }
}

.table td {
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
    min-height: 1rem;
    vertical-align: middle;

    @media all and (max-width: $mobile-breakpoint) {
        white-space: normal;
    }
}

.table th.actions {
    width: 10rem;

    @media all and (max-width: $mobile-breakpoint) {
        width: auto;
        min-width: 4.25rem;
        padding: .3rem .5rem;
    }
}

.table th.log {
    max-width: 2rem;
    width: 2rem;

    @media all and (max-width: $mobile-breakpoint) {
        max-width: 2.5rem;
        width: 2.5rem;
    }

    @media all and (max-width: $extra-mobile-breakpoint) {
        max-width: 1.5rem;
        width: 1.5rem;
    }
}

.table td.small-td {
    max-width: 5rem;
}

.table td.quick-td {
    max-width: 10rem;
}

.table td.fill {
    padding: 0;
    position: relative;
}

.table td.ellipsis {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table tr.red {
    background-color: rgba(255, 0, 0, 0.2);
}

.table tr.yellow {
    background-color: rgba(255, 255, 0, 0.2);
}

.table tr:last-child td {
    border-bottom: none;
}

.table tr:hover td {
    background: rgba($brand-main, 0.2);
}

.table tr.selected td {
    background: rgba($brand-main, 0.4);
}


.table-column-maximize {
    width: 99%;
}

.table .no-data-message {
    color: rgba($text, 0.4);
    text-align: center;
}

.table td.linked {
    padding: 0;
}

.table td a {
    color: $text;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.25rem 0.5rem;
}

.table.condensed {
    tr {
        overflow: visible;
    }

    th {
        font-size: $font-normal;
    }

    td {
        white-space: normal;
        padding-bottom: 0.5rem;
        font-size: 1.3rem;
    }
}

.table .readonly i.icon {
    fill: $readonly;
}