@import "styles/variables.scss";

.login.register-form {
    min-height: 65rem;

    .invitation-text {
        line-height: 1.5rem;
    }

    input[name='invitation_code'] {
        margin-bottom: 1rem;
    }

    .login-body {
        display: grid;
        gap: 1rem;

        .input-container.left-icon {
            svg {
                fill: #9D9FA6;
            }
        }

        .input-disabled {
            box-shadow: 0 0 0.4rem 0 rgba(0,0,0,.5);
            background: #fff;
            padding-left: 4rem;
        }

        .checkbox {
            &.error {

                &,
                .checkbox-text a {
                    color: $validation-error;
                }

            }

            .checkbox-indicator {
                fill: #333;
                position: absolute;

                i.icon {
                    font-size: $font-large !important;
                }
            }

            .checkbox-text {
                padding-left: 3rem;
                display: inline-block;
                font-size: $font-normal;

                a {
                    display: inline-block;
                    color: $text-accent;
                    font-weight: bold;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            gap: 1.6rem;
        }

        button {
            margin-top: 1rem;
        }
    }

    .login-footer {
        grid-template-columns: minmax(7rem, max-content);
        justify-content: flex-end;

        .login-footer-back-btn {
            line-height: 3.25rem;
            padding: 0;
            min-width: 11rem;
        }
    }
}