@import "styles/variables.scss";

.meal-warning-modal-modal {
    display: grid;

    .tooltip-button {
        button {
            width: 100%;
        }
    }

    .modal-content {
        ul li {
            font-size: 1.4rem;
            margin-top: .7rem;

            &:first-child {
                margin-top: 0;
            }
        }


        .two-columns {
            grid-column: span 2;

            span {
                font-size: 1.4rem;
                text-transform: capitalize;
            }

            position: relative;

            .chevron-icon {
                position: absolute;
                top: calc(50% - 6.2rem);
                left: 50%;
                font-size: 3rem;
                transform: translate(-50%, 0);
                color: #000;
            }

            .two-columns-ingredients {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1.1rem 5rem;

                .org {
                    text-align: right;
                }

                .altr {
                    text-align: left;
                }

            }

            .two-columns-buttons {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1.5rem 5rem;
                padding-top: 1rem;

                button {
                    width: 100%;
                    // max-width: 15rem;
                    font-size: 1.35rem;
                    margin: 0 auto auto;
                }

                .keep-original-area-button {
                    display: grid;
                    gap: 1.25rem;

                    button {
                        margin-right: 0;
                        margin-left: auto;
                    }
                }

                .keep-changes-area-button {
                    display: grid;
                    gap: 1.25rem;

                    button {
                        margin-right: auto;
                        margin-left: 0;
                    }
                }
            }
        }

        .warning-section {
            .icon {
                text-align: center;

                i {
                    font-size: 3rem;
                    color: $brand-red;
                }
            }

            display: grid;
            gap: 1.25rem;
            grid-template-columns: 3rem 1fr;
            padding: 1rem;

            padding-bottom: 1rem;
            margin-bottom: 1rem;

            position: relative;

            // $colore: rgba(218, 32, 38, .04);
            // background-color: $colore;
            // border: .25rem solid darken($colore, 11%);

            $colore: rgba(248, 248, 248, .8);
            background-color: $colore;
            border: .25rem solid darken($colore, 10%);

            .double-column {
                grid-column: 2 / 2;
                justify-content: flex-end;
            }

            .warning-section-content {
                display: grid;
                gap: .75rem;
                padding-top: .3rem;

                &>span {
                    line-height: 1.8rem;
                    font-size: 1.4rem;
                }
            }

            .buttons-area {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                justify-content: flex-end;
                grid-column: span 2;
            }

            .agree-section {
                margin-right: auto;
                display: flex;
                gap: .75rem;
                align-items: center;

                padding-top: .5rem;
                padding-bottom: .5rem;
                user-select: none;

                &.disabled {
                    cursor: normal;
                    pointer-events: none;
                    opacity: .5;
                }

                button {
                    padding: 0.3rem .75rem .18rem;
                    background: white;
                    color: transparentize(#000, .85);

                    outline: 1px solid transparent;
                    border: 1px solid transparentize(#000, .8);

                    width: 2.7rem;
                    height: 2.6rem;
                    padding: 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 2.3rem;
                    }

                    &.checked {
                        // outline: 1px solid darken(#598b35, .4);
                        color: transparentize(#598b35, .05);
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        padding: 0;

                        i {
                            font-size: 2rem;
                            line-height: 1;
                        }

                        border-color: transparentize(#598b35, .35);
                    }
                }

                span {
                    font-size: 1.4rem;
                    line-height: 1;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .warning-keep {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                text-align: center;
                max-width: 20rem;
                font-size: 1.2rem !important;
            }

            .warning {
                color: $validation-error;
            }

            @media screen and (max-width: $small-mobile-breakpoint) {
                padding-left: 0 !important;
            }
        }
    }

    button.option-btn {
        background-color: rgba(228, 228, 228, 0.42);
        color: #000;
        line-height: 1.8rem;
        font-size: 1.3rem;
        padding: .5rem 1rem;

        &.active {
            background-color: #86C35B;
            color: #fff;
        }

        &.rebalance {
            min-width: 6.5rem;
        }

        &.no-rebalance {
            min-width: 7rem;
        }
    }

    .modal-content {
        padding: 1rem 1rem 0;
        display: grid;
    }

    .description {
        font-size: 1.7rem;
        display: inline-block;
        box-sizing: border-box;
        line-height: 1.9rem;
        padding-bottom: 1.5rem;
    }

    .modal-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
        padding: 1rem 1rem .25rem;

        button {
            line-height: 2.2rem;

            &.disabled {
                &.green {
                    opacity: .5;
                    background-color: darken(desaturate(#87c45c, .1), .4);
                }
            }

        }

        .cancel-btn {
            background: darken(#F8F8F8, .3);
            color: black;
            border-color: rgba(136, 136, 136, .8);
        }
    }

    @media screen and (max-width: $small-mobile-breakpoint) {
        .modal-content {
            .two-columns {

                .two-columns-ingredients,
                .two-columns-buttons {
                    grid-template-columns: 1fr;
                    gap: 2rem;

                    .org {
                        position: relative;

                        &:after {
                            text-transform: none;
                            content: 'to';
                            display: block;
                            position: absolute;
                            color: #333;
                            bottom: -1.3em;
                            font-weight: bold;
                        }
                    }

                    .org,
                    .altr {
                        text-align: left;
                    }

                    button {
                        margin: 1rem auto auto auto !important;
                    }
                }

                .chevron-icon {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .modal-buttons {
            grid-template-columns: 1fr;
        }

        .modal-content {
            padding: 1rem 0;

            button {
                line-height: 2rem;
            }

            .buttons-area {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            .warning-section {
                padding: 1rem;
                gap: 1rem;
                grid-template-columns: 1.5rem 1fr;

                .icon i {
                    font-size: 1.5rem;
                    top: .25rem;
                    position: relative;
                }
            }
        }

        .two-columns-ingredients {
            padding: 1rem 0;
        }
    }

}