@import "styles/variables.scss";

.ios-pwa-install-prompt {
    max-width: 35rem;
    bottom: 2rem;
    width: 100%;
    background-color: #F1F8FE;
    border-radius: 3px;
    width: calc(100% - 3.5rem);
    left: 50%;
    transform: translate(-50%, 0);

    font: -apple-system-body;
    font-family: -apple-system, "blisslight", blisslight, "blissregular", blissregular, Arial, Helvetica, sans-serif;
    padding: .8rem .75rem .7rem 1.25rem;
    z-index: 999999;
    position: fixed;
    font-size: $font-normal;

    i {
        font-size: $font-header;
    }

    .share-outline {
        fill: #1083FF;
        color: #1083FF;
    }

    display: flex;
    align-items: center;
    gap: .75rem;

    &:after {
        border-style: solid;
        border-width: 2.5rem 2.8rem 0;
        border-color: #F1F8FE transparent transparent transparent;
        content: '';
        position: fixed;
        left: 50%;
        bottom: -1.54rem;
        transform: translate(-50%, 0);
        z-index: -1;
    }
}