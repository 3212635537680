@import "styles/variables.scss";

label.label {
    font-size: $font-normal;
    padding: 0.5rem 0rem;
    border: 1px solid rgba(0, 0, 0, 0);
    display: block;
    margin: auto;
    box-sizing: border-box;
    width: 100%;
    line-height: 1.5rem;
}

label.inline {
    display: inline-block;
    width: 20rem;
}

label.backText {
    position: absolute;
    z-index: 4;
    font-size: $font-small;
    background: #fff;
    padding: 0;
    border: 1px solid $border-light-grey;
    color: #333;
    top: -1.25rem;
    left: .7rem;
    margin: auto;
    display: block;
    box-sizing: border-box;
    width: auto;
    padding: 0 .25rem;
    user-select: none;
    &.large {
        top: -.85rem;
        left: 1rem;
    }
}

label.has-error {
    border-color: $validation-error;
    color: $validation-error;
}

@media screen and (max-width: $small-breakpoint) {
    label.backText {
        top: -1rem !important;
        left: .5rem !important;
    }
}