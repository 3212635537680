@import "styles/variables.scss";

.recipe-item-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    grid-template-areas:
        "image itemHeader"
        "details details";

    .image {
        background-size: cover;
        background-position: center center;
        width: 15rem;
        height: 15rem;

        &.no {
            display: grid;
            width: 12.5rem;
            height: 13rem;
        }
    }

    .recipe-icon,
    .rte-icon {
        margin: 1.25rem auto auto auto;

        i.icon {
            // opacity: 0.6;
            font-size: 8rem;
        }
    }

    .respectNewlines {
        white-space: pre-line;
    }

    .item-header {
        grid-area: itemHeader;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;

        .item-name {
            font-size: $font-header;
            line-height: 2.5rem;

            a {
                color: $text;
                text-transform: capitalize;
            }
        }

        .item-info {
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0.5rem;
            grid-template-rows: 4rem minmax(4rem, min-content);
            margin-top: auto;
            font-size: 1.2rem;

            &.rte {
                gap: 0.5rem;
                grid-template-columns: 1fr;
                grid-template-rows: unset !important;

                .servings-change-container {
                    grid-template-columns: unset;

                    .control-input-with-portion {
                        display: grid;
                        grid-template-columns: repeat(2, minmax(9rem, max-content));
                        justify-content: center;
                        padding-top: .5rem;

                        @media screen and (max-width: $mobile-breakpoint) {
                            grid-template-columns: 1fr;
                            gap: .5rem;
                            padding: .5rem 0;
                            box-sizing: border-box;

                            .number-field {
                                max-width: unset;
                                grid-template-columns: auto minmax(min-content, 10rem) auto;

                                span {
                                    padding: .35rem;
                                    box-sizing: border-box;
                                    font-size: $font-header;

                                    i {
                                        font-size: $font-header;
                                    }
                                }
                            }
                        }

                        @media screen and (max-width: $extra-mobile-breakpoint) {
                            gap: 1.25rem;

                            .number-field {
                                grid-template-columns: auto auto auto;
                            }
                        }
                    }
                }

            }

            button {
                cursor: default;

                &.time-display-button {
                    i.icon {
                        font-size: 2rem;
                    }

                    padding: 0.5rem;
                    border-radius: 0.5rem;
                }
            }

            .servings-change-container {
                grid-column: span 3;
                display: grid;
                grid-template-columns: 1fr 1fr;
                border: 1px solid #ddd;
                border-radius: 5px;
                background: #f6fafa;

                button.serving-button {
                    padding: 0.5rem;
                    box-shadow: none;
                    grid-template-columns: 1fr;
                    background: #f6fafa;
                    color: #777;

                    .readonly-value {
                        font-size: $font-normal;
                        font-weight: normal;
                        color: black;

                        b,
                        .fraction-part {
                            font-weight: normal !important;
                        }
                    }

                    .servings-label {
                        grid-template-columns: min-content auto;
                        display: grid;
                        justify-content: center;
                        gap: 0.5rem;
                        padding-bottom: 0.5rem;

                        @media screen and (max-width: $extra-mobile-breakpoint) {
                            align-items: center;
                            height: 2.5rem;
                        }
                    }

                    .number-field {
                        max-width: 9rem;
                        margin: 0 auto;
                        width: 100%;
                        grid-template-columns: auto minmax(min-content, 5rem) auto;
                        color: #666;

                        i {
                            color: #666
                        }

                        @media screen and (max-width: $mobile-breakpoint) {
                            max-width: unset;
                            grid-template-columns: auto auto auto;

                            span {
                                font-size: $font-header;
                                padding: .5rem 1rem;
                                width: 100%;
                                text-align: center;
                                box-sizing: border-box;
                            }
                        }
                    }
                }

                @media screen and (max-width: $extra-mobile-breakpoint) {
                    grid-template-columns: 1fr;
                }
            }

            .servings-save-panel {
                &.warning-visible {
                    .kcal-warning {
                        &>div {
                            display: inline-block;
                        }
                    }
                }

                .kcal-warning {
                    grid-column: 1/3;
                    padding: 0.6rem;

                    &>div {
                        display: none
                    }

                    @media all and (max-width: $mobile-breakpoint) {
                        position: absolute;
                        bottom: -.5rem;
                        width: 100%;
                        text-align: center;
                    }

                    span {
                        margin-right: 3px;
                    }
                }

                button {
                    padding: 0.6rem;
                    cursor: pointer;
                }

                @media screen and (max-width: $mobile-breakpoint) {
                    button {
                        font-size: $font-normal;
                        padding: 0.5rem 1.5rem;
                    }
                }

                @media screen and (max-width: $extra-mobile-breakpoint) {
                    button {
                        padding: 0.25rem;
                    }
                }

                width: 100%;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 0.5rem;
                right: 0;
                grid-column: span 3;
                opacity: 0;
                height: 0rem;
                display: none;

                &.open {
                    opacity: 1;
                    height: 2.75rem;
                    display: grid;
                }

                @media all and (max-width: $mobile-breakpoint) {
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 2.5rem;
                    grid-auto-flow: dense;

                    &.open {
                        &.warning-visible {
                            height: 5rem;
                        }

                        position: relative;
                    }
                }
            }

            button {
                font-size: unset;
                text-transform: unset;
                display: grid;
                grid-template-columns: min-content 1fr;
                align-items: center;

                @media screen and (max-width: $extra-mobile-breakpoint) {
                    padding: 0.5rem;
                }

                .button-content {
                    display: grid;
                    grid-template-rows: 1fr;

                    @media screen and (max-width: $extra-mobile-breakpoint) {
                        grid-template-rows: auto;
                        grid-template-columns: 1fr;
                    }
                }
            }

            .serving-button {

                &:focus,
                &:active {
                    outline: none;
                }

                .current {
                    grid-template-columns: minmax(8rem, max-content) 2rem;
                }
            }

            .full {
                grid-column: span 2;
            }
        }
    }

    .details {
        grid-area: details;
        font-size: $font-large;
        line-height: 2rem;

        .ingredients {
            font-size: $font-large;
            line-height: 1.5rem;
        }

        .ingredients table {
            width: 100%;
        }

        .ingredients table td {
            padding: 0.5rem;
        }

        .ingredients table td:last-child {
            @media screen and (max-width: $extra-mobile-breakpoint) {
                width: 0;
                overflow: hidden;
            }
        }

        .ingredients table tr:nth-child(odd) {
            background: rgba($brand-green, 0.2);
        }

        .ingredients .collapse-button button {
            background: lighten($brand-green, 10%) !important;
        }
    }

    .buttons {
        display: grid;
        box-sizing: border-box;
        grid-template-columns: auto minmax(21.5rem, min-content) minmax(12rem, min-content);
        grid-gap: 1rem;
        gap: 1rem;
        align-items: center;
        margin: 0 0 1.5rem;

        .nutrients-tooltip {
            .nutrients-title {
                line-height: 1.4;
                display: none;
            }

            i {
                vertical-align: bottom;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 2.75rem;

            span {
                align-self: baseline;
            }

            i.icon {
                line-height: 1rem;
            }
        }
    }
}

.recipe-item-grid .bar {
    background: lighten($brand-green, 10%);
    color: $brand-green-text;
    font-size: $font-large;
    padding: 0.5rem;
}

.recipe-item-grid {
    .altered-warning {
        line-height: 1.9rem;
        padding: 0 1.4rem;
        box-sizing: border-box;
        font-size: 1.4rem;
        color: $validation-error;
        margin-bottom: .35rem;
        display: flex;
        gap: .5rem;

        span {
            font-size: $font-header;
        }
    }

    .pad {
        padding: 1rem;

        i.icon {
            fill: $brand-green;
        }
    }
}

@media all and (max-width: $mobile-breakpoint) {
    .recipe-item-grid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "image"
            "itemHeader"
            "details";

        .image,
        .image.no {
            margin: auto;
            width: 10rem;
            height: 10rem;
        }

        .recipe-icon {
            margin: auto;

            i.icon {
                font-size: 8rem;
            }
        }

        .item-header {
            .item-name {
                padding: 0 !important;
                text-align: center;
                line-height: 1.2;
            }
        }

        .buttons {
            font-size: 1.3rem;
            grid-template-columns: 4.5rem auto auto;

            button {
                font-size: 1.3rem;
            }

            .nutrients-tooltip {
                margin-right: 0 !important;

                button {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 470px) {
    .recipe-item-grid {
        .buttons {
            grid-template-columns: 1fr;

            .nutrients-tooltip {
                .nutrients-title {
                    display: inline-block;
                }

                i {
                    vertical-align: baseline;
                }

            }
        }
    }
}

@media screen and (max-width: 420px) {
    .recipe-item-grid {
        .item-header .item-info {
            grid-template-rows: auto minmax(4rem, min-content) !important;
        }
    }
}