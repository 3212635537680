@import "styles/variables.scss";

.shared-shopping {
    box-sizing: border-box;

    .shared-message {
        box-sizing: border-box;
        margin-top: 5rem;
        line-height: 2;

        text-align: center;

    }

    .back-login-btn {
        color: $text-accent;

        &:hover {
            background: $text-accent;
            color: white;
        }
    }

    .shopping-loading-list {
        box-sizing: border-box;
    }
}