.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon svg {
  height: 1em;
}

.icon.fw {
  display: inline-block;
  width: 2rem;
}
