@import "styles/variables.scss";

.exclamation-mark {
    font-weight: bold;
    font-size: 2rem;
    color: $calendar-base-color;
}

.shopping-loading-list {
    padding: 6rem 2rem 3rem;
    box-sizing: border-box;
    width: 100%;
    grid-column: span 2;
    text-align: center;

    color: $text-accent;

    span {
        padding-top: 1rem;
    }

    i {
        font-size: 3rem;
    }
}

.shopping-list-grid {
    &.visible-hidden {
        visibility: hidden !important;
    }

    grid-area: shopping-list;
    gap: 1rem;
    margin-bottom: 1rem;

    .refresh {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: auto auto;
        gap: 1rem;

        &.generate {
            gap: 2rem;

            @media screen and (max-width: $small-breakpoint) {
                grid-template-columns: auto;
                text-align: center;
                padding-top: 1rem;
                padding-bottom: 1rem;
                gap: 2.5rem;
            }

            @media screen and (max-width: $mobile-breakpoint) {
                font-size: $font-normal;

                button {
                    font-size: $font-normal;
                }
            }
        }
    }

    .progress-save {
        display: grid;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    button.progress {
        height: 3.4rem;

        &.wider {
            max-width: none;
            min-width: 20rem;
            height: auto;
            min-height: 3.4rem;
        }

        max-width: 20rem;
        width: 100%;

        i {
            margin-left: 1rem;
        }
    }

    .shopping-no-data {
        .shopping-header {
            background: rgba(218, 237, 204, 0.6);
            color: $header-bar;
            padding: 0.5rem 0.5rem;
            font-weight: bold;
            white-space: nowrap;
            text-align: center;
            font-size: $font-large;
            position: relative;

            &.deleted {
                background: #f5f5f5;
            }

            @media print {
                padding-left: 1rem !important;
                text-align: left !important;
                margin-bottom: 0;
            }
        }

        .no-data-text {
            position: relative;
        }
    }

    .medium {
        font-size: $font-large;
    }

    .table.readonly {
        opacity: 0.7;
        pointer-events: none;

        tbody {
            color: #333;
            user-select: none;
        }
    }

    &.static {
        .table.readonly {
            opacity: 1;
            pointer-events: auto;

            tbody {
                color: #000;
                user-select: auto;
            }
        }

        .shopping-list-buttons {
            justify-content: flex-end;
            grid-template-columns: 12rem;
        }
    }

    &.static.obsolete {
        .table.readonly {
            opacity: 0.7;
            pointer-events: none;

            tbody {
                color: #333;
                user-select: none;
            }
        }
    }

    span.text-block {
        padding: 0.5rem 0.3rem;
        width: 33.3333%;

        @media print {
            padding: 0 !important;
        }

        &.brand {
            margin-right: auto;
        }
    }

    span.full-width {
        width: 100%;
    }

    .recipe {
        .name {
            color: $brand-blue-light-text;
        }
    }

    .food {
        .name {
            color: $brand-green-text2;
        }
    }

    .shopping-list-buttons {
        @media print {
            display: none;
        }

        display: grid;
        justify-content: space-between;
        grid-template-columns: auto 12rem;
        gap: 0.75rem;
        margin: 1rem 0;

        &.readonly {
            opacity: 0.7;
            pointer-events: none;
        }

        button {
            border: 1px solid;

            &.blue {
                border-color: $brand-blue;
            }

            &.blue:hover {
                background-color: $brand-blue-text;
                color: $brand-blue;
                fill: $brand-blue;
                border: 1px solid;
            }
        }

        .share-button {
            color: $brand-blue;
            border: 1px solid $brand-blue;

            &:hover {
                background-color: $brand-blue;
                color: $brand-blue-text;
                fill: $brand-blue-text;
                border: 1px solid $brand-blue;
            }
        }

        .left-side {
            display: grid;
            gap: 0.75rem;
            grid-template-columns: 12rem 4rem;
        }

        @media screen and (max-width: $mobile-breakpoint) {
            margin: 1.25rem 0 1.25rem;
            grid-template-columns: auto auto;
        }

        @media screen and (max-width: $extra-mobile-breakpoint) {
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            justify-content: space-between;
            align-items: center;

            .left-side {
                display: grid;
                gap: 0.5rem;
                grid-template-columns: 1fr 4rem;
            }
        }
    }

    .shopping-table-extra-space {
        padding: 0 0.5rem !important;
    }

    .shopping-header {
        position: relative;
        background: rgba(218, 237, 204, 0.6);
        padding: 0.5rem;
        color: $header-bar;

        &.additional,
        &.shopping {
            background: #f5f5f5;
        }

        &.shopping {
            margin-bottom: 1rem;

            &.readonly {
                opacity: 0.7;
            }

            .date {
                font-size: 1.1rem;
                vertical-align: bottom;
            }
        }

        &.deleted {
            background: #f5f5f5;
            margin-top: 2rem;
        }

        @media screen and (max-width: $extra-mobile-breakpoint) {
            font-size: $font-normal;
            word-break: break-word;
        }

        @media print {
            padding-left: 0.5rem !important;
            text-align: left;
        }

        .add-item {
            cursor: pointer;
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;

            @media print {
                display: none;
            }
        }
    }

    .table.shopping-table {
        th {
            background: transparent;
            padding: 0;
        }
    }

    .list-no-padding {
        padding: 0 !important;
    }

    .custom-icon {
        width: 21px;
        display: inline-block;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        line-height: 1rem;
        font-weight: normal;
    }

    .selected-cols {
        padding: 0.25rem 0.5rem;
        display: grid;
        grid-template-columns: 3rem auto 1fr 6rem 9rem max-content;
        gap: 0 1rem;
        font-size: $font-large;
        align-items: center;
        position: relative;

        &.is-dirty {
            background: rgba(51, 51, 51, 0.08);
        }

        &.dont-buy {
            span.text {
                position: relative;
                text-decoration: line-through;
            }
        }

        &.selected-additional {


            .name {
                grid-column: span 2;
                display: flex;
                align-items: center;
                gap: 2rem;

                i.icon {
                    display: flex;
                    align-items: center;
                }


            }
        }

        .menu-requires-text {
            position: relative;
            grid-column: span 2/6;
            font-size: 1.2rem;
            padding: 0;
            padding-left: 0.75rem;
        }

        span.text-block {
            width: auto;

            &.name {
                white-space: normal;
            }
        }

        .select-log {
            align-self: center;
            justify-self: center;
            top: 0.1rem;

            i.icon {
                fill: $brand-green;
                color: $brand-green;
            }

            &.deleted {
                color: #c0c0c0;
            }
        }

        span.faded {
            opacity: 0.3;
            user-select: none;
        }

        .trash-icon {
            padding: 0.5rem 0.74rem 0.5rem 1.5rem !important;
            cursor: pointer;
        }

        .shopping-row-buttons {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: space-between;
            align-items: center;
            font-size: $font-normal;

            div {
                display: grid;
                grid-template-columns: auto auto;
            }

            .restore {
                padding: 0.5rem 0.74rem 0.5rem 1.5rem !important;
                cursor: pointer;

                .tooltip .tooltip-content {
                    top: -2rem;
                }
            }

            i {
                color: $brand-green;
                fill: $brand-green;
                cursor: pointer;
            }
        }
    }

    @media print {
        .shopping-list-name-bar {
            grid-template-columns: 1fr;

            .bar-action {
                display: none;
            }

            height: 2rem;
            padding: 0;
            margin: 0 !important;
            margin-top: 1rem;
        }
    }
}

.shopping-list-grid {
    @media print {
        .selected-cols {
            grid-template-columns: 3rem auto 1fr 4rem 9rem max-content !important;
            gap: 0 0.5rem;
            padding: 0 !important;
            white-space: normal;
        }

        table {
            margin-bottom: 1.5rem;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .shopping-table {
            tbody {
                tr:last-child {
                    .selected-cols {
                        border-bottom: none !important;
                    }
                }

            }
        }

        .selected-cols {
            border-bottom: #EFEFEF 1px solid;
            padding-right: 0.25rem;
            gap: 0 0.5rem;
            white-space: normal;

            .text-block {
                &.name {
                    grid-column: span 4;
                }

                &.brand {
                    grid-column: span 5;
                }

                &.fraction {
                    grid-column: span 2;
                    text-align: left !important;
                }

                &.portion {
                    grid-column: span 2;
                }
            }

            &.selected-additional {
                .text-block {
                    &.name {
                        grid-column: span 5;
                    }

                    &.brand {
                        grid-column: span 5;
                    }
                }

                &.fraction {
                    text-align: left !important;
                }

                &.portion {
                    grid-column: span 2;
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .table th.log {
            max-width: 2.5rem !important;
            width: 2.5rem !important;
        }

        .selected-cols {
            padding-right: 0.25rem;
        }
    }

    // .not-real-portion-type {
    //     font-style: italic;
    //     color: rgba(0, 0, 0, 1);
    //     position: relative;

    //     &:after {
    //         color: rgba(0, 0, 0, .8);
    //         content: '*';
    //         padding-left: .1rem;
    //     }
    // }
}