@import "styles/variables.scss";

.food-unit-picker-component {
    position: relative;
    .picker-items.hidden {
        display: none;
    }
    .picker-items {
        display: block;
        z-index: 10;
        position: absolute;
        left: 0;
        top: 3rem;
        background: white;
        width: 100%;
        box-sizing: border-box;
        max-height: 200px;
        overflow-y: scroll;
        padding: 0;
        border: 1px solid #e0e0e0;
        border-radius: 0 0 3px 3px;

        li {
            list-style: none;
            margin: 0;
            width: auto;
            padding: 1rem;
            font-size: 1.5rem;
            &:hover {
                background: lightgray;
                cursor: pointer;
            }
        }
    }
}
