@import "styles/variables.scss";

.settings-pattern.my-menu {
    .week-grid.for-my-menu {
        opacity: 1;
    }

    .manual-servings {
        font-size: 1.25rem;
        color: #333;
        text-align: center;
        padding-left: .25rem;
        width: 100%;
    }

    .progress-icon {
        position: absolute;

        justify-content: center;
        align-items: center;
        font-size: 6rem;
        color: $calendar-base-color;
        fill: $calendar-base-color;

        display: none;
        width: 0;
        height: 0;
        z-index: -1;
    }

    .week-grid-my-menu-loading {
        position: relative;

        .progress-icon {
            animation: fadeInFromNone .1s ease-out forwards;
            animation-delay: .4s;

            opacity: 0;
            display: flex;
            width: 100%;
            height: 100%;
            z-index: 3;
        }

        .week-grid.for-my-menu {
            animation: tableFade .1s ease-out forwards;
            animation-delay: .4s;
        }
    }

    .settings-switch-buttons {
        display: flex;
        justify-content: space-between;

        max-width: 87rem;
        width: 100%;
        margin-left: auto;

        i {
            font-size: 4rem;
            color: #00AAA7;
        }

        .left,
        .right {
            display: flex;
            align-items: center;
            gap: 1.25rem;
            cursor: pointer;

            .text {
                font-size: 1.3rem;
                line-height: 1.8rem;
            }
        }

        .left {}

        .right {}

    }
}