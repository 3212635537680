@import "styles/variables.scss";

.text-green {
    color: $brand-green;
}

.text-blue {
    color: $brand-blue;
}

.text-red {
    color: $brand-red;
}

.text-light {
    color: $text-light;
}

.text-lighter {
    color: $text-lighter;
}

.text-white {
    color: $white;
}

.text-blue-light {
    color: $brand-blue-light-text;
}

.text-tall-line {
    line-height: 2rem;
}

.text-accent {
    color: $text-accent;
}

.text-small {
    font-size: 80%;
}

.text-left {
    text-align: left !important;
}

.text-large {
    font-size: 120%;
}

.text-normal {
    font-weight: normal;
}

.text-bold {
    font-weight: bold;
}

.text-unbold {
    font-weight: normal !important;
}

.text-block {
    text-align: center;
    box-sizing: border-box;
    padding: 0.5rem 1.5rem;
    font-size: $font-normal;
    line-height: 1.6rem;
    border: none;
    display: inline-block;
    width: 100%;
}

.text-block-green {
    background-color: $brand-green;
    color: $brand-green-text;
}

.text-has-error {
    color: $validation-error !important;
}

.full-width {
    width: 100%;
}

.underline {
    text-decoration: underline;
}

.hover-underline {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.break-word {
    word-break: break-word;
}