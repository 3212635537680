@import "styles/variables.scss";

header .logo {
    margin: auto;
    display: inline-block;
}

header .logo img {
  padding: 1.5rem;
  max-height: 3.75rem;
  display: inline-block;
}

@media screen and (min-width: $mobile-breakpoint) and (max-width: $small-breakpoint){
  header .logo img {
    padding: 1rem;
    max-height: 5rem;
  }
}

