@import "styles/variables.scss";

.input-search {
    position: relative;
    box-sizing: border-box;

    .loading-dots {
        padding: 1.25rem 1rem 0.5rem;
    }

    &.in-progress {
        .result-wrapper {
            .results {
                div.result-wrapper {
                    display: none;
                }
            }
        }
    }

    .input-container {
        i.icon {
            line-height: 1.5rem !important;
        }

        &.input-disabled {
            min-height: 3.17rem;
            background: rgba(243, 243, 243, 0.2);

            .selected {
                background: transparent;
            }
        }
    }

    .selected {
        display: inline-block;
        box-sizing: border-box;
        font-size: $font-normal;
        line-height: 2rem;
        background: white;
    }

    .result-wrapper {
        position: relative;
        box-sizing: border-box;


        * {
            scrollbar-width: thin;
        }

        *::-webkit-scrollbar {
            width: 8px;
        }


        &>span {
            box-sizing: border-box;
            display: inline-block;
            width: 100%;
            cursor: pointer;
            padding: 0.75rem 1rem;

            &:hover {
                font-weight: 400;
                background: rgba(221, 221, 221, 0.3);
            }

            &.selected {
                font-weight: bold;
            }
        }

        .results {
            width: 100%;
            box-sizing: border-box;
            position: absolute;
            z-index: 99;
            visibility: hidden;
            background-color: $white;
            height: 1px;
            transition: 0.3s height ease-in-out;
            max-height: 38vh;
            user-select: none;
            font-size: $font-normal;
            border: 1px solid #e1e4e8;
            border-radius: 3px;
            overflow: auto;

            &.visible {
                height: auto;
                border: 1px solid $border-light-grey;
                visibility: visible;
                box-shadow: 0px 0.4rem 0.4rem 0px rgba(0, 0, 0, 0.1);
                overflow-y: auto;
            }

            .result-caption {
                text-transform: uppercase;
                font-size: 0.9rem;
                letter-spacing: -0.1px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                display: inline-block;
                color: #c1c1c1;
                margin: 0;
                width: 100%;
                line-height: 1;
                padding: 1.25rem 1rem 0.5rem;
            }

        }
    }
}