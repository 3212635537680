@import "styles/variables.scss";

.changeamount-modal {
    display: grid;
    grid-gap: 1.5rem;
    padding: 1rem 2rem;

    .controls {
        padding: 1rem;
        box-shadow: none;
        background: #f6fafa;
        color: #777;
        border: 1px solid #ddd;
        border-radius: 5px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        input {
            background: white;
        }

        span.fraction {
            padding: 0.5rem;
            border: 1px solid #e0e0e0;
        }
    }

    span.has-title {
        line-height: 1.1;
        display: flex;
        gap: 0.2rem;

        i.icon {
            margin-right: 0.5rem;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 1rem .5rem;

        button {
            line-height: 2.5rem;
        }
    }
}