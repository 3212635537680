@import "styles/variables.scss";

.header-button-circle {
    padding: 1.5rem 0;
    margin: 0 1rem;
    font-size: $font-normal;
    fill: #FFF;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    text-transform: uppercase;
    border-radius: 4rem;
    display: inline-block;
    width: 7rem;
    height: 7rem;
    font-size: 3.5rem;
    position: relative;
    z-index: 1;
    box-shadow:
        rgba(0, 0, 0, 0.4) 0 0.35rem 0.7rem -0.1rem;
    box-sizing: border-box;
    background: $text-lighter;
    border: 1px solid rgba($text-light, .3);

    i.icon {
        display: block;

        svg {
            height: 5rem;
        }
    }

    &.left {
        float: left;
    }

    &.right {
        float: right;
    }


    @media print {
        display: none;
    }

    &>span {
        display: block;
    }

    .between-circles {
        position: absolute;
        left: 7rem;
        top: 2rem;
        font-size: 3rem;
        color: rgba($text-light, .3);
    }


    &.active {
        cursor: default;
        box-shadow:
            rgba(0, 0, 0, 0.4) 0 0.35rem 0.7rem -0.1rem,
            inset 0px 0px 0px 5px #FFF;
        color: #FFF;

        &:hover {
            box-shadow:
                rgba(0, 0, 0, 0.4) 0 0.35rem 0.7rem -0.1rem,
                inset 0px 0px 0px 5px #FFF;
        }
    }

    &:hover {
        box-shadow:
            rgba(0, 0, 0, 0.4) 0 0.35rem 0.7rem -0.1rem,
            inset 0px 0px 0px 5px rgba(#FFF, 0.3);
    }


    &.no-hover:hover {
        box-shadow: rgba(0, 0, 0, 0.4) 0 0.35rem 0.7rem -0.1rem;
    }

    &.completed {
        .between-circles {
            color: rgba($brand-green, 0.3)
        }
    }

    &.completed,
    &.active,
    &.visited {
        background: darken($brand-green, 15%);
        border: 1px solid $brand-green-border;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .header-button-circle {
        width: 4.25rem;
        height: 4.25rem;
        margin-top: .75rem;
        font-size: 2.25rem;
        padding: 1rem .5rem;

        .between-circles {
            left: 4.25rem;
            top: 1rem;
            font-size: 2.5rem;
        }

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            margin-left: 0;
        }

        .fa-stack {
            line-height: 2rem !important;
            width: 100% !important;
        }

        i.icon svg {
            height: 3rem;
        }

        &.active {
            cursor: default;
            box-shadow:
                rgba(0, 0, 0, 0.4) 0 0.35rem 0.7rem -0.1rem,
                inset 0px 0px 0px 3px #FFF;
            color: #FFF;

            &:hover {
                box-shadow:
                    rgba(0, 0, 0, 0.4) 0 0.35rem 0.7rem -0.1rem,
                    inset 0px 0px 0px 3px #FFF;
            }
        }

        &:hover {
            box-shadow:
                rgba(0, 0, 0, 0.4) 0 0.35rem 0.7rem -0.1rem,
                inset 0px 0px 0px 3px rgba(#FFF, 0.3);
        }
    }
}

@media all and (max-width: $extra-mobile-breakpoint) {
    .header-button-circle {
        margin: .75rem .5rem 0;
    }
}

@media print {
    .header-button-circle i.icon svg {
        height: 3rem;
    }

    .header-button-circle {
        padding: 0.5rem 0.5rem;
        font-size: $font-tiny;
    }
}