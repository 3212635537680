@import "styles/variables.scss";

.tutorialWrapper {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    content: '';
    z-index: 5;


    &.no-bg {
        background: transparent;
    }

    &.with-shadow {
        .tutorial-element {
            .container {
                box-shadow: 0px 1rem 3rem 0px rgba(0, 0, 0, 0.14);
            }
        }
    }

    &.small {
        .tutorial-element {
            max-width: 20rem;
        }
    }

    .tutorial-element {
        position: absolute;
        display: block;
        max-width: 25rem;
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 1.5rem;

        &.arrow-left {
            &:after {
                left: 12px;
            }
        }

        &.arrow-right {
            &:after {
                left: auto;
                right: 0;
                transform: none;
            }
        }

        &:after {
            position: absolute;
            content: '';
            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-top: 1rem solid #fff;
            left: 50%;
            bottom: .5rem;
            transform: translate(-50%, 0);
        }

        &>.container {
            max-width: 100%;
            box-sizing: border-box;
            background-color: $white;
            border-radius: 3px;
        }

        .modal-close-button {
            position: absolute;
            right: -.8rem;
            top: -.85rem;
            font-size: 1.8rem;
            z-index: 1;
        }

        .modal-close-button:hover {
            color: $text-lighter;
            fill: $text-lighter;
        }
    }
}

.tutorial-element-bg {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    pointer-events: none;
    z-index: -1;
}

@media screen and (max-width: 1400px) {
    .tutorial-element {
        max-width: 15rem;
    }
}

// @media screen and (max-width: $mobile-breakpoint) {
//     .tutorial-element {
//         padding: 1rem;
//     }
// }