@import "styles/variables.scss";

.table-ingredient-row {
    display: grid;

    &.with-icon {
        grid-template-columns: minmax(2rem, min-content) 1fr;
        align-items: center;
        grid-gap: .25rem;
        gap: .25rem;

        .table-multi-recipe-icon {
            align-self: flex-start;

            i.icon {
                font-size: 1.4rem;
                position: relative;
                right: .25rem;
                top: .1rem;
            }
        }
    }

    i.brand {
        font-weight: bold;
        color: #75C469;
        font-style: italic;
        padding-left: .2rem;
    }

    i.icon {
        color: $brand-green;
        fill: $brand-green;
    }

    .main {
        font-size: $font-large;
        line-height: 2.5rem;

        &.is-warning {
            display: flex;
            gap: 0 .5rem;
            flex-wrap: wrap;

            .warning-sign {
                color: $validation-error;
            }

            .original {
                padding: 0 .16rem;
                position: relative;

                text-decoration: line-through solid $validation-error;
                text-decoration-thickness: 2px;
                display: inline-block;
            }

            .text {
                display: flex;
                gap: .25rem;
            }
        }
    }

    .left {
        font-size: $font-normal;
        line-height: 1.5rem;
    }

    @media screen and (min-width: $small-breakpoint) {
        .main {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 39rem;
            width: 100%;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .main {
            white-space: normal;
        }
    }

    @media screen and (max-width: $extra-mobile-breakpoint) {
        &.with-icon {
            .table-multi-recipe-icon {
                i.icon {
                    font-size: 1.2rem;
                    right: 0;
                }
            }
        }
    }
}